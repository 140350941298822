import React from "react";
import styled from "styled-components";

const NoticeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoticeTableWrapper = styled.div`
    width: 100vh;
    height: 85vh;
    display: flex;
    justify-content: center;
`;

const PaymentResult: React.FC = () => {
    return (
        <NoticeWrapper>
            <NoticeTableWrapper></NoticeTableWrapper>
        </NoticeWrapper>
    );
};

export default PaymentResult;
