import * as FaIcons from "react-icons/fa";
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai";
export const SidebarData = [
  // {
  //   title: "대시보드",
  //   path: "/dashboard",
  //   icon: <FaIcons.FaRegChartBar />,
  // },
  {
    title: "라이더 관리",
    path: "/user",
    icon: <FaIcons.FaUsers />,
  },
  // {
  //   title: "광고 상영 목록",
  //   path: "/commercialManage",
  //   icon: <AiIcons.AiFillBuild />,
  // },
  {
    title: "관리자 관리",
    path: "/Managements",
    icon: <MdIcons.MdManageAccounts />,
  },
];
