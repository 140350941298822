import React from "react";
import styled from "styled-components";
import UserTable from "components/UserTable/User_Table";

const UserWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 50px;
`;
const UserTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const User: React.FC = () => {
  return (
    <UserWrapper>
      <UserTableWrapper>
        <UserTable />
      </UserTableWrapper>
    </UserWrapper>
  );
};

export default User;
