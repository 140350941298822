import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const DetailWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;
const Wrapper = styled.div`
  width: 70%;
  height: 95%;
`;
const BoxWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 25%;
  font-size: 24px;
  margin: 40px 0px;
  padding: 1px;
`;
const RiderDetail = styled.div`
  width: 50%;
  margin-left: 10px;
`;
const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 20%;
  border: 0.5px solid gray;
  box-shadow: 0 2px 4px gray;
  border-radius: 5px;
  padding-left: 8px;
  margin-bottom: 5px;
`;
const InfoTitle = styled.div`
  width: 50%;
  margin-left: 30px;
`;
const SubWrapper = styled.div`
  width: 100%;
  display: flex;
`;
const SubTitle = styled.div`
  width: 33%;
  height: 30px;
  font-weight: bold;
  font-size: 24px;
`;
const DisplayDate = styled.div`
  width: 33%;
  display: flex;
  justify-content: center;
  font-size: 24px;
`;
const DisplayButton = styled.div`
  width: 34%;
  display: flex;
  justify-content: center;
`;
const MoveButton = styled.button`
  border-radius: 5px;
  width: 30px;
  height: 30px;
  font-size: 25px;
  color: #019868;
  border: solid 1px #019868;
  margin: 2px 0px;
  background-color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const DWMButton = styled(MoveButton)`
  width: 60px;
  font-size: 18px;
  margin: 2px 5px;
`;

const RideNotes = styled.textarea`
  width: 100%;
  height: 20%;
  margin-top: 20px;
  border-radius: 3px;
  border: solid 2px gray;
  font-size: 20px;
  padding: 15px;
  text-align: left;
  vertical-align: top;
  resize: none;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
`;
const ModiOrSaveButton = styled.button<{ Save?: any }>`
  width: 150px;
  height: 80px;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => (props.Save ? "white" : "#019868")};
  background-color: ${(props) => (props.Save ? "#019868" : "white")};
  border-radius: 5px;
  border: 1px solid ${(props) => (props.Save ? "#019868" : "#019868")};
`;

const Detail: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [saveState, setSaveState] = useState<Boolean>(false);
  const [info, setInfo] = useState({
    id: "",
    name: "",
    email: "",
    phoneNumber: "",
    agency: "",
    subscribe_day: "",
    start_subscribe: "",
    displacement: "",
    licensePlate: "",
    model: "",
    insuranceSubscription: "",
  });
  const [drivingInfo, setDrivingInfo] = useState({
    rapidStart: 0,
    rapidAccel: 0,
    rapidDecel: 0,
    rapidTurn: 0,
    overSpeed: 0,
    totalTime: 0,
    Score: 0,
  });
  const [memo, setMemo] = useState<string>();
  const team = localStorage.getItem("TEAM");

  const params = new URLSearchParams(location.search);

  let userId = params.get("id");
  console.log(userId);

  useEffect(() => {
    if (!userId) {
      navigate("/user");
    }
    axios
      .post("https://api2.riderlog-backoffice.net/rider/detail/info", {
        id: userId,
      })
      .then((res) => {
        console.log(res.data.RESULT);
        setMemo(res.data.RESULT.memo);
        setInfo({
          id: res.data.RESULT.id,
          name: res.data.RESULT.name,
          email: res.data.RESULT.email,
          phoneNumber: res.data.RESULT.phoneNumber,
          agency: res.data.RESULT.agency,
          subscribe_day: "",
          start_subscribe: "",
          insuranceSubscription: "보험에 가입되어 있지 않습니다.",
          displacement: res.data.RESULT.vehicle.displacement,
          licensePlate: res.data.RESULT.vehicle.licensePlate,
          model: res.data.RESULT.vehicle.model,
        });

        if (res.data.RESULT.revenues.length === 1) {
          if (res.data.RESULT.revenues[0].revCategory === "보험") {
            setInfo((prev) => ({
              ...prev,
              insuranceSubscription: "보험에 가입되어 있습니다.",
            }));
          } else {
            setInfo((prev) => ({
              ...prev,
              insuranceSubscription: "보험에 가입되어 있지 않습니다.",
              subscribe_day: res.data.RESULT.revenues[0].start_date,
              start_subscribe: res.data.RESULT.revenues[0].months,
            }));
          }
        } else if (res.data.RESULT.revenues.length === 2) {
          if (res.data.RESULT.revenues[0].revCategory === "보험") {
            setInfo((prev) => ({
              ...prev,
              insuranceSubscription: "보험에 가입되어 있습니다.",
              subscribe_day: res.data.RESULT.revenues[0].start_date,
              start_subscribe: res.data.RESULT.revenues[0].months,
            }));
          } else {
            setInfo((prev) => ({
              ...prev,
              insuranceSubscription: "보험에 가입되어 있습니다.",
              subscribe_day: res.data.RESULT.revenues[1].start_date,
              start_subscribe: res.data.RESULT.revenues[1].months,
            }));
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 메모 업데이트
  const updateMemo = () => {
    axios
      .patch(
        "https://api2.riderlog-backoffice.net/rider/memo",
        { id: info.id, memo: memo },
        {
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then(() => {
        alert("메모 수정을 완료 하였습니다.");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  var now = new Date();
  var nowYear = now.getFullYear();
  nowYear += nowYear < 2000 ? 1900 : 0;
  var weekStartDate = new Date(
    nowYear,
    now.getMonth(),
    now.getDate() - now.getDay()
  );
  var weekEndDate = new Date(
    nowYear,
    now.getMonth(),
    now.getDate() + (6 - now.getDay())
  );
  const [dateFrom, setDateFrom] = useState<any>(weekStartDate);
  const [dateEnd, setDateEnd] = useState<any>(weekEndDate);

  const [BindDateFrom, setBindDateFrom] = useState(getToday(dateFrom));
  const [BindDateEnd, setBindDateEnd] = useState(getToday(dateEnd));

  const [nowState, setNowState] = useState("Week");

  function getToday(e: any) {
    var date = e;
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);

    return year + "-" + month + "-" + day;
  }

  useEffect(() => {
    let EndDate = BindDateEnd;

    if (nowState === "Day") {
      EndDate = BindDateFrom;
    }

    axios
      .post("https://api2.riderlog-backoffice.net/rider/detail/driving", {
        id: userId,
        startDate: BindDateFrom,
        endDate: EndDate,
      })
      .then((res) => {
        const Data = res.data;
        // console.log(Data);
        if (Data.length === 0) {
          setDrivingInfo({
            rapidStart: 0,
            rapidAccel: 0,
            rapidDecel: 0,
            rapidTurn: 0,
            overSpeed: 0,
            totalTime: 0,
            Score: 0,
          });
        } else {
          let start: number = 0;
          let accel: number = 0;
          let decel: number = 0;
          let turn: number = 0;
          let speed: number = 0;
          let time: number = 0;
          let score: number = 0;

          for (let i = 0; i < Data.length; i++) {
            start = start + Data[i].rapid_start_count;
            accel = accel + Data[i].rapid_accel_count;
            decel = decel + Data[i].rapid_decel_count;
            turn = turn + Data[i].rapid_turn_count;
            speed = speed + Data[i].overSpeed_70_count;
            time = time + Data[i].total_time;
            score = score + Data[i].safe_score;
          }

          setDrivingInfo({
            rapidStart: start,
            rapidAccel: accel,
            rapidDecel: decel,
            rapidTurn: turn,
            overSpeed: speed,
            totalTime: time,
            Score: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [BindDateFrom, BindDateEnd, nowState]);

  useEffect(() => {
    nowState === "Day"
      ? setBindDateFrom(getToday(dateFrom))
      : setBindDateFrom(getToday(dateFrom));
    setBindDateEnd(getToday(dateEnd));
  }, [dateEnd]);

  const dayClick = () => {
    setNowState("Day");
    setDateFrom(dateFrom);
    getToday(dateFrom);
  };

  const weekClick = () => {
    let date = new Date(dateFrom);
    var nowYear = date.getFullYear();
    nowYear += nowYear < 2000 ? 1900 : 0;
    var weekStartDate = new Date(
      nowYear,
      date.getMonth(),
      date.getDate() - date.getDay()
    );
    var weekEndDate = new Date(
      nowYear,
      date.getMonth(),
      date.getDate() + (6 - date.getDay())
    );
    setNowState("Week");
    setDateFrom(weekStartDate);
    setDateEnd(weekEndDate);
    getToday(dateFrom);
    getToday(dateEnd);
  };

  const monthClick = () => {
    let date = new Date(dateFrom);
    setNowState("Month");
    setDateFrom(new Date(date.getFullYear(), date.getMonth(), 1));
    setDateEnd(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    getToday(dateFrom);
    getToday(dateEnd);
  };

  const nextDay = () => {
    setDateFrom(() => {
      let date = new Date(dateFrom);
      if (nowState === "Day") {
        return new Date(date.setDate(date.getDate() + 1));
      } else if (nowState === "Week") {
        return new Date(date.setDate(date.getDate() + 7));
      } else if (nowState === "Month") {
        return new Date(date.setMonth(date.getMonth() + 1));
      }
    });
    setDateEnd(() => {
      let dateF = new Date(dateFrom);
      let dateE = new Date(dateEnd);
      if (nowState === "Day") {
        return new Date(dateE.setDate(dateE.getDate() + 1));
      } else if (nowState === "Week") {
        return new Date(dateE.setDate(dateE.getDate() + 7));
      } else {
        return new Date(dateF.getFullYear(), dateF.getMonth() + 2, 0);
      }
    });
  };
  const beforeDay = () => {
    setDateFrom(() => {
      let date = new Date(dateFrom);
      if (nowState === "Day") {
        //let date = new Date(dateFrom);
        return new Date(date.setDate(date.getDate() - 1));
      } else if (nowState === "Week") {
        return new Date(date.setDate(date.getDate() - 7));
      } else {
        return new Date(date.setMonth(date.getMonth() - 1));
      }
    });
    setDateEnd(() => {
      let dateF = new Date(dateFrom);
      let dateE = new Date(dateEnd);
      if (nowState === "Day") {
        return new Date(dateE.setDate(dateE.getDate() + 1));
      } else if (nowState === "Week") {
        return new Date(dateE.setDate(dateE.getDate() - 7));
      } else {
        return new Date(dateF.getFullYear(), dateF.getMonth() + 0, 0);
      }
    });
  };

  return (
    <DetailWrapper>
      <Wrapper>
        <SubTitle>사용자 기본정보</SubTitle>
        <BoxWrapper>
          <RiderDetail>
            <InfoWrapper>
              <InfoTitle>이름</InfoTitle>
              <InfoTitle>{info.name}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>이메일</InfoTitle>
              <InfoTitle>{info.email}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>바이크 용량</InfoTitle>
              <InfoTitle>{info.displacement}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>번호판</InfoTitle>
              <InfoTitle>{info.licensePlate}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>최근 구독 시작일</InfoTitle>
              <InfoTitle>{info.subscribe_day}</InfoTitle>
            </InfoWrapper>
          </RiderDetail>
          <RiderDetail>
            <InfoWrapper>
              <InfoTitle>전화번호</InfoTitle>
              <InfoTitle>{info.phoneNumber}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>소속</InfoTitle>
              <InfoTitle>{info.agency}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle> 바이크 종류</InfoTitle>
              <InfoTitle>{info.model}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle> 보험 가입 여부</InfoTitle>
              <InfoTitle>{info.insuranceSubscription}</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>구독기간</InfoTitle>
              <InfoTitle>{info.start_subscribe}개월</InfoTitle>
            </InfoWrapper>
          </RiderDetail>
        </BoxWrapper>
        <SubWrapper>
          <SubTitle> 안전점수 정보</SubTitle>
          <DisplayDate>
            {nowState !== "Day" ? (
              <div>{`${BindDateFrom} ~ ${BindDateEnd}`} </div>
            ) : (
              <div> {`${BindDateFrom}`} </div>
            )}
            <MoveButton style={{ marginLeft: "20px" }} onClick={beforeDay}>
              {"<"}
            </MoveButton>
            <MoveButton onClick={nextDay}> {">"} </MoveButton>
          </DisplayDate>
          <DisplayButton>
            <DWMButton onClick={dayClick}> 일간 </DWMButton>
            <DWMButton onClick={weekClick}> 주간 </DWMButton>
            <DWMButton onClick={monthClick}> 월간 </DWMButton>
          </DisplayButton>
        </SubWrapper>
        <BoxWrapper>
          <RiderDetail>
            <InfoWrapper>
              <InfoTitle>급가속 횟수</InfoTitle>
              <InfoTitle>{drivingInfo.rapidAccel} 회</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>급감속 횟수</InfoTitle>
              <InfoTitle>{drivingInfo.rapidDecel} 회</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>과속 횟수</InfoTitle>
              <InfoTitle>{drivingInfo.overSpeed} 회</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>급회전 횟수</InfoTitle>
              <InfoTitle>{drivingInfo.rapidTurn} 회</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>급출발 횟수</InfoTitle>
              <InfoTitle>{drivingInfo.rapidStart} 회</InfoTitle>
            </InfoWrapper>
          </RiderDetail>

          <RiderDetail>
            <InfoWrapper>
              <InfoTitle>안전 운전 점수</InfoTitle>
              <InfoTitle>미정</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>운행 시간</InfoTitle>
              <InfoTitle>{drivingInfo.totalTime} 시간</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>안전 평가 기준 1</InfoTitle>
              <InfoTitle>상위 70%</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>안전 평가 기준 2</InfoTitle>
              <InfoTitle>상위 47%</InfoTitle>
            </InfoWrapper>
            <InfoWrapper>
              <InfoTitle>안전 평가 기준 3</InfoTitle>
              <InfoTitle>상위 20%</InfoTitle>
            </InfoWrapper>
          </RiderDetail>
        </BoxWrapper>
        <SubTitle> 특이사항</SubTitle>
        {saveState ? (
          <RideNotes
            onChange={(e: any) => {
              setMemo(e.target.value);
            }}
          />
        ) : (
          <RideNotes value={memo} disabled />
        )}
        {team === "company" ? (
          <ButtonWrapper>
            {saveState ? (
              <ModiOrSaveButton
                onClick={() => {
                  setSaveState(false);
                  updateMemo();
                }}
                Save
              >
                저장
              </ModiOrSaveButton>
            ) : (
              <ModiOrSaveButton
                onClick={() => {
                  setSaveState(true);
                }}
              >
                수정
              </ModiOrSaveButton>
            )}
          </ButtonWrapper>
        ) : (
          <div />
        )}
      </Wrapper>
    </DetailWrapper>
  );
};

export default Detail;
