import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    html{
        height:100vh;
        // overflow:hidden;
    }

    // Table 관련 css
    table {
        font-family: Arial, Helvetica, sans-serif;
        border-collapse: collapse;
        width: 100%;
        font-size: 18px;
    }
    .ManagerTH{
        background-color:#808080
    }
    table td, table th {
        border: 1px solid #ddd;
        padding: 15px;
        font-size: 14px;
    }
    table tr:nth-child(even){
        background-color: #f2f2f2;
    }
    table tr:hover {
        background-color: #ddd;
    }
    table th {
        padding-top: 12px;
        padding-bottom: 12px;
        background-color:#019868;
        color: white;
    }
    
   
`;
