import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";
import CheckBox from "../CheckBox";
import axios from "axios";

// style_Components
const StyleTable = styled.table`
  width: 90%;
`;
const TableBody = styled.div`
  margin: 10px 0;
`;
const SearchBar = styled.input`
  padding: 10px;
  font-size: 18px;
  border: solid 2px gray;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;
const FilterBoxWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonGroup = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Button = styled.button<{ Big?: any }>`
  width: ${(props) => (props.Big ? "70px" : "40px")};
  border-radius: 5px;
  height: 40px;
  font-size: 20px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const MovePageWrapper = styled.div`
  width: 300px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const MovePage = styled.input`
  width: 50px;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const UpDownWrapper = styled.span`
  position: fixed;
`;
const DeleteButton = styled.button<{ ready?: any }>`
  width: 70px;
  margin: 15px 80px 0px 0px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.ready ? "red" : "gray")};
  color: white;
  cursor: ${(props) => (props.ready ? "pointer" : "block")};
`;
const CreateButton = styled.button`
  width: 80px;
  margin-right: 20px;
  height: 40px;
  border: solid 1px #019868;
  border-radius: 5px;
  background-color: white;
  color: #019868;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const OutModalButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid gray;
  background-color: white;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
export const ModalView = styled.div.attrs((props) => ({
  role: "dialog",
}))`
  width: 45%;
  height: 80%;
  text-align: start;
  text-decoration: none;
  padding: 30px 90px;
  background-color: white;
  border-radius: 30px;
`;
export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModifyButton = styled.button`
  width: 100%;
  font-size: 22px;
  height: 40px;
  border: solid 1px #019868;
  border-radius: 5px;
  background-color: white;
  color: #019868;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const ModalContentWrapper = styled.div`
  width: 100%;
  height: 9%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
const ModalContentTitle = styled.span`
  width: 200px;
  font-size: 24px;
  font-weight: bold;
`;
const ModalContentInput = styled.input`
  width: 90%;
  padding: 10px;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 2px gray;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
  }
`;

/* src/page/main.css */
const ReplyDiv = styled.div`
  margin-top: 40px;
  h4 {
    margin-top: 50px;
    margin-left: 30px;
  }
  button {
    position: absolute;
    width: 7%;
    height: 97.5px;
    margin-top: -0px;
    background-color: white;
    border: solid 1px #ababab;
    cursor: pointer;
  }
`;
const ReplyWrite = styled.div`
  margin-top: 15px;
  height: 150px;
  textarea {
    width: 80%;
    padding: 10px;
    resize: none;
    font-size: 15px;
    line-height: 25px;
    margin-left: 30px;
  }
`;

const ReplyListData = styled.div`
  display: flex;
  font-size: 12px;
  width: 100%;
  color: #ababab;
  margin-bottom: 5px;
`;
const ReplyDeleteBtn = styled.div`
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 20px;
  cursor: pointer;
  border-radius: 5px;
  border: solid 1px #ababab;
  color: black;
`;
const ReplyList = styled.div`
  padding: 15px;
  border-top: solid 1px #ababab;
  border-bottom: solid 1px #ababab;
  width: 100%;
  height: 40%;
  overflow-y: scroll;
`;

const COLUMNS = [
  {
    Header: "번호",
    accessor: "id",
  },
  {
    Header: "제목",
    accessor: "title",
  },
  {
    Header: "작성자",
    accessor: "rider_name",
  },
  {
    Header: "등록일",
    accessor: "createdAt",
  },
];

const QuestionTable: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [checkedId, setCheckedId] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [detailId, setDetailId] = useState("");
  const [detailTitle, setDetailTitle] = useState("");

  const [detailCompany_user, setDetailCompany_user] = useState("");
  const [detailContent, setDetailContent] = useState("");

  const [comment, setComment] = useState<string>();
  const [commentArr, setCommentArr] = useState<any[]>([]);

  const openModalHandler = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    // init detail data
    setDetailId("");
    setDetailTitle("");

    setDetailCompany_user("");
    setDetailContent("");
  };

  // 첫 데이터 가져오는 부분
  useEffect(() => {
    const offset = 1000 * 60 * 60 * 9;
    const datas: any = [];
    axios
      .get(`https://api2.riderlog-backoffice.net/question`)
      .then((response) => {
        console.log("success get users data : ", response.data);
        response.data.RESULT.map((item: any) => {
          return datas.push({
            id: item.id,
            title: item.title,
            content: item.content,
            rider_name: item.rider.name,
            createdAt: new Date(
              new Date(item.createdAt).getTime() + offset
            ).toLocaleString("ko"),
          });
        });
        setUsers(datas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = useMemo(() => COLUMNS, []);

  const data = users;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
          {
            id: "modify",
            Header: "",
            Cell: ({ row }: any) => (
              <ModifyButton
                onClick={() => {
                  // 기존
                  axios
                    .get(
                      `https://api2.riderlog-backoffice.net/question/${row.original.id}`
                    )
                    .then((response) => {
                      console.log("success get users data2 : ", response.data);

                      setDetailId(response.data.RESULT.id);
                      setDetailTitle(response.data.RESULT.title);
                      setDetailContent(response.data.RESULT.content);
                      setDetailCompany_user(response.data.RESULT.rider.name);
                      setCommentArr(response.data.RESULT.comments);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  openModalHandler();
                }}
              >
                comment
              </ModifyButton>
            ),
          },
        ];
      });
    }
  );
  const { pageIndex, globalFilter } = state;

  // 삭제
  useEffect(() => {
    setCheckedId(selectedFlatRows);
  }, [selectedFlatRows]);

  const DeleteChecked = () => {
    let DeleteResultId: number[] = [];
    checkedId.map((row) => {
      DeleteResultId.push(row.original.id);
    });
    // 체크된 row의 id
    console.log(DeleteResultId);

    if (window.confirm(DeleteResultId.length + "개를 삭제 하시겠습니까?")) {
      axios
        .delete("https://api2.riderlog-backoffice.net/question", {
          data: { questionId: DeleteResultId },
        })
        .then(() => {
          alert("삭제 성공하였습니다.");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const _addReply = () => {
    console.log("comment : ", comment);
    console.log(detailId);
    axios
      .post(
        "https://api2.riderlog-backoffice.net/comment",
        { content: comment, questionId: detailId },
        {
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        alert("답변 등록을 완료 하였습니다.");
        closeModal();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      {isOpen ? (
        <ModalBackdrop>
          <ModalView>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <OutModalButton
                onClick={() => {
                  closeModal();
                }}
              >
                x
              </OutModalButton>
            </div>
            <ModalContentWrapper>
              <ModalContentTitle>*제목 :</ModalContentTitle>
              <ModalContentInput
                type="text"
                defaultValue={detailTitle}
                disabled
              />
            </ModalContentWrapper>
            <ModalContentWrapper>
              <ModalContentTitle> 작성자 :</ModalContentTitle>
              <ModalContentInput
                type="text"
                defaultValue={detailCompany_user}
                disabled
              />
            </ModalContentWrapper>
            <ModalContentWrapper>
              <ModalContentTitle>내용 :</ModalContentTitle>
              <ModalContentInput
                type="text"
                defaultValue={detailContent}
                disabled
              />
            </ModalContentWrapper>

            {commentArr.length > 0 ? (
              <ReplyList>
                <ReplyListData
                  style={{
                    fontSize: "20px",
                    fontWeight: "bold",
                    color: "black",
                    marginBottom: "15px",
                  }}
                >
                  <div style={{ width: "15%" }}>작성자</div>
                  <div style={{ width: "60%" }}>내용</div>
                  <div
                    style={{
                      width: "15%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    작성일
                  </div>
                  <div
                    style={{
                      width: "10%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    버튼
                  </div>
                </ReplyListData>
                {commentArr.map((el) => {
                  return (
                    <ReplyListData>
                      <div
                        style={{
                          width: "15%",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {el.company_user.name}
                      </div>
                      <div
                        style={{
                          width: "60%",
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "black",
                        }}
                      >
                        {el.content}
                      </div>
                      <div
                        style={{
                          width: "15%",
                          fontSize: "14px",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {new Date(
                          new Date(el.createdAt).getTime() + 1000 * 60 * 60 * 9
                        )
                          .toLocaleString("ko")
                          .substring(0, 11)}
                      </div>
                      <ReplyDeleteBtn
                        onClick={() => {
                          let DeleteId: number = el.id;
                          // let DeleteId: number[] = [];
                          // DeleteId.push(el.id);
                          if (
                            window.confirm("해당 댓글을 삭제 하시겠습니까?")
                          ) {
                            axios
                              .delete(
                                "https://api2.riderlog-backoffice.net/comment",
                                {
                                  data: { commentId: DeleteId },
                                  headers: {
                                    authorization: localStorage.getItem("JWT"),
                                  },
                                }
                              )
                              .then(() => {
                                alert("삭제 성공하였습니다.");
                                window.location.reload();
                              })
                              .catch((err) => {
                                console.log(err);
                              });
                          }
                        }}
                      >
                        삭제
                      </ReplyDeleteBtn>
                    </ReplyListData>
                  );
                })}
              </ReplyList>
            ) : (
              <h5> 작성된 댓글이 없습니다. </h5>
            )}
            <ReplyDiv>
              <h4> 댓글 </h4>
              <ReplyWrite>
                <textarea
                  rows={3}
                  placeholder="100자 이내의 글을 입력해주세요."
                  maxLength={100}
                  name="write_reply"
                  onChange={(e: any) => {
                    setComment(e.target.value);
                  }}
                ></textarea>
                <button
                  onClick={() => {
                    _addReply();
                  }}
                >
                  등록
                </button>
              </ReplyWrite>
            </ReplyDiv>
          </ModalView>
        </ModalBackdrop>
      ) : null}

      <StyleTable>
        <FilterBoxWrapper>
          <SearchBar
            placeholder="Search"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </FilterBoxWrapper>
        <TableBody>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={"ManagerTH"}
                    >
                      {column.render("Header")}
                      <UpDownWrapper>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <GoArrowUp />
                          ) : (
                            <GoArrowDown />
                          )
                        ) : (
                          ""
                        )}
                      </UpDownWrapper>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")} </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableBody>
        <ButtonWrapper>
          <div style={{ width: "300px" }}>
            {checkedId.length === 0 ? (
              <DeleteButton>삭제</DeleteButton>
            ) : (
              <DeleteButton ready onClick={DeleteChecked}>
                삭제
              </DeleteButton>
            )}
          </div>
          <ButtonGroup>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            ​
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              Big
            >
              Prev
            </Button>
            <span>
              <strong
                style={{
                  display: "block",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                {pageIndex + 1} / {pageOptions.length}
              </strong>
            </span>
            ​
            <Button onClick={() => nextPage()} disabled={!canNextPage} Big>
              Next
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </ButtonGroup>
          <MovePageWrapper>
            <MovePage
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  alert("잘못 입력하셨습니다");
                  e.target.value = "";
                  return;
                }
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
            <div>페이지로 이동</div>
          </MovePageWrapper>
        </ButtonWrapper>
      </StyleTable>
    </>
  );
};
export default QuestionTable;
