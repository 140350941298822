import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import {
  ModalBackdrop,
  ModalView,
  OutModalButton,
  SaveButton,
  ModalContentTitle,
  ModalContentInput,
} from "components/NoticeTable/App_Notice_Table";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  // useFilters,
} from "react-table";
import axios from "axios";

// style_Components
const StyleTable = styled.table`
  width: 90%;
`;
const TableBody = styled.div`
  margin: 10px 0;
`;
const SearchBar = styled.input`
  padding: 10px;
  font-size: 18px;
  border: solid 2px gray;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;

const FilterBoxWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonGroup = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Button = styled.button<{ Big?: any }>`
  width: ${(props) => (props.Big ? "70px" : "40px")};
  border-radius: 5px;
  height: 40px;
  font-size: 20px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const MovePageWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;
const MovePage = styled.input`
  width: 50px;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UpDownWrapper = styled.span`
  position: fixed;
`;

const BlackTr = styled.tr`
  cursor: pointer;
  color: black;
`;

const ClientSelect = styled.select`
  width: 50%;
  padding: 0.7em 0.5em;
  border: 1px solid #999;
  font-size: 20px;
  border-radius: 10px;
  margin-right: 20px;
  &:focus {
    border: 1px solid #019868;
    box-sizing: border-box;
  }
`;

export const COLUMNS = [
  {
    Header: "이름",
    accessor: "name",
  },
  {
    Header: "이메일",
    accessor: "Email",
  },
  {
    Header: "전화번호",
    accessor: "phoneNumber",
  },
  {
    Header: "보험 상품",
    accessor: "salesProduct",
  },
  {
    Header: "보험 금액",
    accessor: "salesAmount",
  },
  {
    Header: "요율",
    accessor: "yoyul",
  },
  {
    Header: "예상 금액",
    accessor: "expected_amount",
  },
  {
    Header: "입금 금액",
    accessor: "actual_amount",
  },
  {
    Header: "확인",
    accessor: "displaying",
  },
];

const InsuranceTable: React.FC = () => {
  const [users, setUsers]: any = useState([]);
  var now = new Date();
  var nowYear = now.getFullYear();
  var monthStartDate = new Date(nowYear, now.getMonth());
  const [dateFrom, setDateFrom] = useState<any>(monthStartDate);
  const [BindDateFrom, setBindDateFrom] = useState("");
  const [expectedSum, setExpectedSum] = useState<number>();
  const [actualSum, setActualSum] = useState<number>();
  const [isOpen, setIsOpen] = useState(false);

  const [allSeller, setAllSeller] = useState([]);
  const [seller, setSeller] = useState<string>();

  const [productName, setProductName] = useState<string>();
  const [commissionRate, setCommissionRate] = useState<string>();

  function getToday(e: Date) {
    var date = e;
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);

    return year + "-" + month;
  }
  // 소속 호출
  useEffect(() => {
    axios
      .get("https://api2.riderlog-backoffice.net/revenue/sellers", {
        headers: {
          authorization: localStorage.getItem("JWT"),
        },
      })
      .then((res) => {
        const resultObj = res.data.RESULT;
        const resultName: any = [];
        resultObj.map((x: any) => {
          resultName.push(x.name);
        });
        setAllSeller(resultName);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  console.log(allSeller, " all seller");
  console.log(seller, " seller");

  useEffect(() => {
    setBindDateFrom(getToday(dateFrom));

    const datas: any = [];
    // setExpectedSum(0);
    // setActualSum(0);
    var expected_sum = 0;
    var actual_sum = 0;
    const call_date = getToday(dateFrom);
    const year = call_date.substring(0, 4);
    const month = call_date.substring(5, 7);

    axios
      .get(
        `https://api2.riderlog-backoffice.net/revenue?category=보험&seller=${seller}&year=${year}&month=${month}`,
        {
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        console.log("success get users data");
        console.log(response.data);

        response.data.RESULT.map((item: any) => {
          expected_sum += item.expected_amount;
          actual_sum += item.actual_amount;

          return datas.push({
            name: item.rider.name,
            Email: item.rider.email,
            phoneNumber: item.rider.phoneNumber,
            salesProduct: item.commission_rate.product.name,
            salesAmount: item.total_amount,
            expected_amount: item.expected_amount,
            actual_amount: item.actual_amount,
            yoyul: item.commission_rate.rate,
            displaying: item.expected_amount === item.actual_amount ? "O" : "X",
          });
        });

        setUsers(datas);
        setExpectedSum(expected_sum);
        setActualSum(actual_sum);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [seller, dateFrom]);

  const nextDay = () => {
    setDateFrom(() => {
      let date = new Date(dateFrom);
      return new Date(date.setMonth(date.getMonth() + 1));
    });
  };
  const beforeDay = () => {
    setDateFrom(() => {
      let date = new Date(dateFrom);
      return new Date(date.setMonth(date.getMonth() - 1));
    });
  };

  const columns = useMemo(() => COLUMNS, []);
  const data = users;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, globalFilter } = state;
  const openModalHandler = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setProductName("");
    setCommissionRate("");
  };
  console.log(productName);
  console.log(commissionRate);
  const saveFunc = () => {
    if (!productName || !commissionRate) {
      alert("값이 비어있습니다.");
    } else {
      axios
        .patch(
          // "revenue/",
          "https://api2.riderlog-backoffice.net/commissionRate/",
          {
            seller: seller,
            productName: productName,
            commissionRate: commissionRate,
          },
          {
            headers: {
              authorization: localStorage.getItem("JWT"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.MESSAGE === "NOT_FOUND") {
            alert("일치하는 보험상품이 없습니다.");
          } else {
            if (+commissionRate > 1) {
              alert("요율은 1을 넘을 수 없습니다.");
            } else {
              alert("저장에 성공했습니다.");
              closeModal();
              window.location.reload();
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {isOpen ? (
        <ModalBackdrop>
          <ModalView style={{ width: "30%", height: "60%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <OutModalButton
                onClick={() => {
                  closeModal();
                }}
              >
                x
              </OutModalButton>
            </div>
            <div
              style={{
                width: "100%",
                height: "70%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                marginBottom: "50px",
              }}
            >
              <div style={{ width: "100%" }}>
                <ModalContentTitle>보험 상품 :</ModalContentTitle>
                <ModalContentInput
                  style={{ width: "100%" }}
                  onChange={(e: any) => {
                    setProductName(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: "100%" }}>
                <ModalContentTitle>요율 :</ModalContentTitle>
                <br />
                <ModalContentInput
                  style={{ width: "100%" }}
                  onChange={(e: any) => {
                    setCommissionRate(e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                height: "8%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <SaveButton
                onClick={() => {
                  saveFunc();
                }}
              >
                저장하기
              </SaveButton>
            </div>
          </ModalView>
        </ModalBackdrop>
      ) : null}
      <StyleTable>
        <FilterBoxWrapper>
          <span style={{ width: "50%" }}>
            <ClientSelect
              onChange={(e: any) => {
                setSeller(e.target.value);
              }}
            >
              <option disabled selected hidden>
                소속을 선택해주세요.
              </option>
              {allSeller.map((x, i) => {
                return (
                  <option value={x} key={i}>
                    {x}
                  </option>
                );
              })}
            </ClientSelect>
            {`${BindDateFrom}`}
            <Button onClick={beforeDay}> {"<"} </Button>
            <Button onClick={nextDay}> {">"} </Button>
          </span>
          <SearchBar
            placeholder="Search"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </FilterBoxWrapper>
        <FilterBoxWrapper></FilterBoxWrapper>
        <TableBody>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      <UpDownWrapper>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <GoArrowUp />
                          ) : (
                            <GoArrowDown />
                          )
                        ) : (
                          ""
                        )}
                      </UpDownWrapper>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <BlackTr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </BlackTr>
                );
              })}
            </tbody>
          </table>
        </TableBody>
        <ButtonWrapper>
          <div style={{ width: "160px" }}>
            <div>총 매출</div>
            <div>예상 : {expectedSum} 원 </div>
            <div>입금 금액 : {actualSum} 원</div>
          </div>
          <div style={{ width: "160px", marginTop: "20px" }}>
            <Button
              Big
              onClick={() => {
                openModalHandler();
              }}
            >
              요율
            </Button>
          </div>
          <ButtonGroup>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>

            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              Big
            >
              Prev
            </Button>
            <span>
              <strong
                style={{
                  display: "block",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                {pageIndex + 1} / {pageOptions.length}
              </strong>
            </span>

            <Button onClick={() => nextPage()} disabled={!canNextPage} Big>
              Next
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </ButtonGroup>
          <div style={{ width: "160px" }} />
          <MovePageWrapper>
            <MovePage
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  alert("잘못 입력하셨습니다");
                  e.target.value = "";
                  return;
                }
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
            <div>페이지로 이동</div>
          </MovePageWrapper>
        </ButtonWrapper>
      </StyleTable>
    </>
  );
};
export default InsuranceTable;
