import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SignUpPageContent = styled.div`
  display: flex;
  justify-content: center;
  height: 90vh;
`;

// style_components
const Wrapper = styled.div`
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  width: 60%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
`;

const IdAndPasswordTitle = styled.div`
  font-size: 22px;
  font-weight: bold;
  margin-top: 30px;
`;

const InputBox = styled.input`
  width: 100%;
  height: 50px;
  padding: 10px;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 2px gray;
  margin-top: 10px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
  }
`;
export const RadioBox = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;

  .d {
    background-color: red;
  }
`;
export const RadioInput = styled.input.attrs({ type: "radio" })`
  width: 150px;
  height: 50px;
  cursor: pointer;
  position: absolute;
  opacity: 0;
  &:checked {
    display: inline-block;
    background: none;
    padding: 0px 10px;
    text-align: center;
    height: 50px;
  }
`;

export const RadioLabel = styled.label`
  font-size: 15px;
  width: 140px;
  height: 40px;
  background: #e6e6e6;
  border-radius: 10px;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #777;
  ${RadioInput}:checked + && {
    color: white;
    background-color: #019868;
  }
`;
const DeptSelect = styled.select`
  width: 100%;
  padding: 0.7em 0.5em;
  border: 1px solid #999;
  font-size: 20px;
  border-radius: 10px;
  margin-top: 10px;
  &:focus {
    border: 1px solid #019868;
    box-sizing: border-box;
  }
`;

const SubmitButton = styled.button`
  font-size: 21px;
  font-weight: bold;
  width: 500px;
  height: 60px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;

const CloseButton = styled.button`
  font-size: 21px;
  width: 500px;
  height: 60px;
  margin-top: 30px;
  border-radius: 5px;
  cursor: pointer;
  color: #777;
  background: #e6e6e6;
  border: 0.3px solid #999;

  &:hover {
    color: black;
    background: #e9e9e9;
  }
`;
const SendEmailButton = styled(SubmitButton)`
  width: 40%;
  height: 50px;
  margin-top: 10px;
`;

const CenterWrapper = styled.div`
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
`;
const ErrorText = styled.div`
  color: red;
  font-size: 15px;
  margin-top: 10px;
`;

const SignUp: React.FC = () => {
  const [team, setTeam] = useState<string>();
  const [name, setName] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [checkEmail, setCheckEmail] = useState<string>();

  // 문자메세지 저장
  const [message, setMessage] = useState<string>();
  // 메세지 확인 됐는지 안됐는지.
  const [checkMessage, setCheckMessage] = useState<boolean>(false);
  // 확인 메일을 받은 이메일 주소.
  const [completedEmail, setCompletedEmail] = useState<string>();

  const [password, setPassword] = useState<string>();
  const [passwordCheck, setPasswordCheck] = useState<string>();
  const [sex, setSex] = useState<string>();
  const [dept, setDept] = useState<string>();
  const [guestEmail, setGuestEmail] = useState<string>();
  // navigation 변경함수.
  const navigation = useNavigate();

  // input 변경 함수.
  const onChangeTeam = (e: any) => {
    setTeam(e.target.value);
    // init
    setName("");
    setPhone("");
    setEmail("");
    setCheckEmail("");
    setPassword("");
    setPasswordCheck("");
    setDept("");
    setGuestEmail("");
    if (e.target.value === "guest") {
      setEmail("auth_guest@star-pickers.com");
    }
  };
  const onChangeName = (e: any) => {
    setName(e.target.value);
  };
  const onChangePhone = (e: any) => {
    setPhone(
      e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`)
    );
  };

  const onChangeEmail = (e: any) => {
    setEmail(e.target.value);
  };

  const onChangeCheckEmail = (e: any) => {
    setCheckEmail(e.target.value);
  };
  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };
  const onChangePasswordCheck = (e: any) => {
    setPasswordCheck(e.target.value);
  };
  const onChangeSex = (e: any) => {
    setSex(e.target.value);
  };
  const onChangeDept = (e: any) => {
    setDept(e.target.value);
  };
  const onChangeGuestEmail = (e: any) => {
    setGuestEmail(e.target.value);
  };
  // email 정규식.
  const EMAILREGEX =
    // eslint-disable-next-line
    /^[0-9a-zA-Z]([-_\.]?[0-9a-zA-Z])*@[0-9a-zA-z]([-_\.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/;
  const sendEmail = (e: any) => {
    console.log(e);
    axios
      .post("https://api2.riderlog-backoffice.net/companyUser/authEmail", {
        EMAIL: e,
        NAME: name,
        PHONE: phone,
        GUEST_EMAIL: guestEmail,
      })
      .then((res) => {
        console.log(res.data);
        setMessage(res.data.MESSAGE);
        setCompletedEmail(res.data.EMAIL);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(guestEmail);
  const createUser = () => {
    axios
      .post("https://api2.riderlog-backoffice.net/companyUser/createUser", {
        NAME: name,
        SEX: sex,
        PHONENUMBER: phone,
        EMAIL: team === "company" ? email : guestEmail,
        PASSWORD: password,
        DEPT: dept,
        TEAM: team,
      })
      .then((res) => {
        if (res.data.CODE === "00") {
          console.log(res.data);
          alert("이미 가입된 이메일입니다.");
        } else {
          console.log(res.data);
          alert("회원가입이 완료되었습니다.");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkFucntion = () => {
    if (
      !email ||
      !phone ||
      !email ||
      !password ||
      !passwordCheck ||
      !sex ||
      !team ||
      (team === "company" && !dept) ||
      (team === "guest" && !guestEmail)
    ) {
      return alert("모든 정보를 기입해주세요.");
    } else {
      if (team === "guest" && !guestEmail?.match(EMAILREGEX)) {
        return alert("이메일이 형식에 부합하지 않습니다.");
      }
      if (checkEmail !== message) {
        return alert("인증번호가 틀렸습니다.");
      }
      if (password.length < 6 || password.length > 14) {
        return alert("비밀번호는 최소 6 자리 , 최대 14 자리 이상이여야 합니다");
      }
      if (passwordCheck !== password) {
        return alert("비밀번호가 일치하지 않습니다.");
      }
      if (checkMessage) {
        return alert("인증번호가 맞는지 확인하기를 눌러주세요.");
      }
      if (email !== completedEmail) {
        return alert("인증받은 이메일 주소가 변경되었습니다.");
      }
    }
    createUser();
    navigation("/");
  };

  return (
    <SignUpPageContent>
      <Wrapper>
        <InputWrapper>
          <div style={{ width: "100%" }}>
            <IdAndPasswordTitle>소속</IdAndPasswordTitle>
            <DeptSelect onChange={onChangeTeam}>
              <option disabled selected hidden>
                소속을 선택해주세요.
              </option>
              <option key={"company"} value={"company"}>
                별따러가자
              </option>
              <option key={"guest"} value={"guest"}>
                외부 게스트
              </option>
            </DeptSelect>
            <IdAndPasswordTitle>이름</IdAndPasswordTitle>
            <InputBox
              type="text"
              onChange={onChangeName}
              value={name || ""}
              name={name}
              placeholder={"홍길동"}
            />
            <IdAndPasswordTitle>전화번호</IdAndPasswordTitle>
            <InputBox
              type="text"
              onChange={onChangePhone}
              value={phone || ""}
              name={phone}
              maxLength={13}
              placeholder={"01011112222"}
            />
            {team === "guest" ? (
              <>
                <IdAndPasswordTitle>본인 이메일</IdAndPasswordTitle>
                <InputBox
                  type="text"
                  onChange={onChangeGuestEmail}
                  value={guestEmail || ""}
                  name={guestEmail}
                  placeholder={"riderlog@star-pickers.com"}
                />
              </>
            ) : null}

            {team === "guest" ? (
              <div style={{ marginTop: "10px", color: "red" }}>
                {/* <IdAndPasswordTitle>인증 이메일</IdAndPasswordTitle>
                <InputBox
                  type="text"
                  onChange={onChangeEmail}
                  value={"beomsouck@star-pickers.com"}
                  name={"beomsouck@star-pickers.com"}
                  disabled
                /> */}
                * 별따러가자에 인증번호를 문의 해주세요.
                {/* <br />* E-mail : auth_guest@star-pickers.com */}
                <br />* 오전 9시 ~ 오후 6시 (공휴일 제외)
              </div>
            ) : (
              <>
                <IdAndPasswordTitle>회사 이메일</IdAndPasswordTitle>
                {message ? (
                  <InputBox
                    type="text"
                    onChange={onChangeEmail}
                    value={email || ""}
                    name={email}
                    disabled
                  />
                ) : (
                  <InputBox
                    type="text"
                    onChange={onChangeEmail}
                    value={email || ""}
                    name={email}
                    placeholder={"riderlog@star-pickers.com"}
                  />
                )}
              </>
            )}

            <IdAndPasswordTitle>이메일 인증번호</IdAndPasswordTitle>
            <div style={{ display: "flex", width: "100%" }}>
              {checkMessage ? (
                <InputBox
                  type="text"
                  onChange={onChangeCheckEmail}
                  value={checkEmail || ""}
                  name={checkEmail}
                  placeholder={"인증번호 입력"}
                  style={{ width: "60%" }}
                />
              ) : (
                <InputBox
                  type="text"
                  value={checkEmail || ""}
                  name={checkEmail}
                  placeholder={"인증번호 입력"}
                  style={{ width: "60%" }}
                  disabled
                />
              )}
              {checkMessage ? (
                <SendEmailButton
                  onClick={() => {
                    if (checkEmail === message) {
                      alert("인증번호 확인이 완료되었습니다.");
                      setCheckMessage(false);
                    } else {
                      alert("인증번호가 불일치합니다.");
                    }
                  }}
                >
                  확인하기
                </SendEmailButton>
              ) : (
                <SendEmailButton
                  onClick={() => {
                    if (email && !email.includes("@star-pickers.com")) {
                      return alert("회사 이메일에서만 사용 가능합니다.");
                    }
                    if (!team) {
                      return alert("소속을 선택해 주세요.");
                    }
                    if (
                      !name ||
                      !phone ||
                      !(team === "company" ? email : guestEmail)
                    ) {
                      return alert(
                        "이름 , 전화번호 , 이메일을 먼저 기입해 주세요."
                      );
                    } else {
                      sendEmail(email);
                      setCheckMessage(true);
                      alert(
                        "인증번호 전송이 완료되었습니다. 입력 후 확인하기를 눌러주세요."
                      );
                    }
                  }}
                >
                  메세지 전송
                </SendEmailButton>
              )}
            </div>

            <IdAndPasswordTitle>비밀번호</IdAndPasswordTitle>
            <InputBox
              type="password"
              onChange={onChangePassword}
              value={password || ""}
              name={password}
            />
            {password && password?.length < 6 ? (
              <ErrorText>비밀번호는 최소 6자 이상이여야 합니다.</ErrorText>
            ) : null}
            {password && password?.length > 14 ? (
              <ErrorText>비밀번호는 최대 14자 이하이여야 합니다.</ErrorText>
            ) : null}
            <IdAndPasswordTitle>비밀번호 확인</IdAndPasswordTitle>
            <InputBox
              type="password"
              onChange={onChangePasswordCheck}
              value={passwordCheck || ""}
              name={passwordCheck}
            />
            {password && passwordCheck ? (
              passwordCheck !== password ? (
                <ErrorText>비밀번호가 일치하지 않습니다.</ErrorText>
              ) : null
            ) : null}
            <IdAndPasswordTitle>성별</IdAndPasswordTitle>
            <RadioBox>
              <div>
                <RadioInput
                  type="radio"
                  onChange={onChangeSex}
                  value={"male"}
                  name={sex}
                />
                <RadioLabel>남자</RadioLabel>
              </div>
              <div>
                <RadioInput
                  type="radio"
                  onChange={onChangeSex}
                  value={"female"}
                  name={sex}
                />
                <RadioLabel>여자</RadioLabel>
              </div>
            </RadioBox>
            {team === "company" ? (
              <>
                <IdAndPasswordTitle>부서</IdAndPasswordTitle>
                <DeptSelect onChange={onChangeDept}>
                  <option value="" disabled selected hidden>
                    부서를 선택해주세요.
                  </option>
                  <option key={"management"} value={"management"}>
                    경영지원
                  </option>
                  <option key={"developer"} value={"developer"}>
                    개발자
                  </option>
                </DeptSelect>
              </>
            ) : null}
          </div>
          <CenterWrapper
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ width: "100%", display: "flex" }}>
              <CloseButton
                onClick={() => {
                  if (window.confirm("정말 뒤로 가시겠습니까 ?")) {
                    navigation("/");
                  }
                }}
              >
                돌아가기
              </CloseButton>
              {checkMessage}
              <SubmitButton
                onClick={() => {
                  checkFucntion();
                }}
              >
                가입하기
              </SubmitButton>
            </div>
          </CenterWrapper>
        </InputWrapper>
      </Wrapper>
    </SignUpPageContent>
  );
};
export default SignUp;
