import AppNoticeTable from "components/NoticeTable/App_Notice_Table";
import React from "react";
import styled from "styled-components";

const NoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoticeTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const AppNotice: React.FC = () => {
  return (
    <NoticeWrapper>
      <NoticeTableWrapper>
        <AppNoticeTable />
      </NoticeTableWrapper>
    </NoticeWrapper>
  );
};

export default AppNotice;
