import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  // useFilters,
} from "react-table";
import axios from "axios";

// style_Components
const StyleTable = styled.table`
  width: 90%;
`;
const TableBody = styled.div`
  margin: 10px 0;
`;
const SearchBar = styled.input`
  padding: 10px;

  font-size: 18px;
  border: solid 2px gray;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;

const FilterBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonGroup = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Button = styled.button<{ Big?: any }>`
  width: ${(props) => (props.Big ? "70px" : "40px")};
  border-radius: 5px;
  height: 40px;
  font-size: 20px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const MovePageWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;
const MovePage = styled.input`
  width: 50px;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UpDownWrapper = styled.span`
  position: fixed;
`;
// const YellowTr = styled.tr`
//   color: #f1b606;
//   cursor: pointer;
// `;
const BlackTr = styled.tr`
  cursor: pointer;
  color: black;
`;

export const COLUMNS = [
  {
    Header: "이름",
    accessor: "banner",
  },
  {
    Header: "광고주",
    accessor: "backoffice_client",
  },
  {
    Header: "광고시작",
    accessor: "start_date",
  },
  {
    Header: "광고기간",
    accessor: "duration_month",
  },
  {
    Header: "입금예정일",
    accessor: "expected_settlement_date",
  },
  {
    Header: "계약금액",
    accessor: "expected_amount",
  },
  {
    Header: "입금금액",
    accessor: "actual_amount",
  },
  {
    Header: "확인",
    accessor: "displaying",
  },
];

const CommercialTable: React.FC = () => {
  const [users, setUsers]: any = useState([]);
  var now = new Date();
  var nowYear = now.getFullYear();
  var monthStartDate = new Date(nowYear, now.getMonth());
  const [dateFrom, setDateFrom] = useState<any>(monthStartDate);
  const [BindDateFrom, setBindDateFrom] = useState("");
  const [expectedSum, setExpectedSum] = useState<number>();
  const [actualSum, setActualSum] = useState<number>();

  function getToday(e: Date) {
    var date = e;
    var year = date.getFullYear();
    var month = ("0" + (1 + date.getMonth())).slice(-2);

    return year + "-" + month;
  }
  useEffect(() => {
    setBindDateFrom(getToday(dateFrom));

    const datas: any = [];
    setExpectedSum(0);
    var expected_sum = 0;
    var actual_sum = 0;
    const call_date = getToday(dateFrom);
    const year = call_date.substring(0, 4);
    const month = call_date.substring(5, 7);
    axios
      .post(
        "https://api2.riderlog-backoffice.net/commercial",
        { YEAR: year, MONTH: month },
        {
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((response) => {
        console.log("success get users data");
        console.log(response.data);
        if (response.data.CODE === "01") {
          console.log("NOT FOUND COMMERCIALS");
        } else {
          response.data.map((item: any) => {
            expected_sum += item.expected_amount;
            actual_sum += item.actual_amount;

            return datas.push({
              banner: "배너" + item.banner,
              backoffice_client: item.backoffice_client.name,
              start_date: item.start_date,
              duration_month: item.duration_month,
              expected_settlement_date: item.expected_settlement_date,
              expected_amount: item.expected_amount,
              actual_amount: item.actual_amount,
              displaying:
                item.expected_amount === item.actual_amount ? "O" : "X",
            });
          });
        }

        setUsers(datas);
        setExpectedSum(expected_sum);
        setActualSum(actual_sum);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dateFrom]);

  const nextDay = () => {
    setDateFrom(() => {
      let date = new Date(dateFrom);
      return new Date(date.setMonth(date.getMonth() + 1));
    });
  };
  const beforeDay = () => {
    setDateFrom(() => {
      let date = new Date(dateFrom);
      return new Date(date.setMonth(date.getMonth() - 1));
    });
  };

  const columns = useMemo(() => COLUMNS, []);
  const data = users;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  // 요일 차이
  const getDateDiff = (d1: any, d2: any) => {
    const date1 = new Date(d1);
    const date2 = new Date(d2);
    const diffDate = date1.getTime() - date2.getTime();
    return Math.abs(diffDate / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
  };
  const today = new Date();

  return (
    <StyleTable>
      <FilterBoxWrapper>
        {
          <div>
            {`${BindDateFrom}`}
            <Button onClick={beforeDay}> {"<"} </Button>{" "}
            <Button onClick={nextDay}> {">"} </Button>
          </div>
        }
        <SearchBar
          placeholder="Search"
          type="text"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </FilterBoxWrapper>
      <FilterBoxWrapper></FilterBoxWrapper>
      <TableBody>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <UpDownWrapper>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <GoArrowUp />
                        ) : (
                          <GoArrowDown />
                        )
                      ) : (
                        ""
                      )}
                    </UpDownWrapper>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              if (
                // 1달 남았을때 (만기 예정)
                getDateDiff(row.values.lastPayment, today) >= 330 &&
                getDateDiff(row.values.lastPayment, today) < 365
              ) {
                return (
                  // <YellowTr {...row.getRowProps()}>
                  //   {row.cells.map((cell) => {
                  //     return (
                  //       <td
                  //         onClick={() => {
                  //           moveDetail(cell.row.values.id);
                  //         }}
                  //         {...cell.getCellProps()}
                  //       >
                  //         {cell.render("Cell")}
                  //       </td>
                  //     );
                  //   })}
                  // </YellowTr>
                  <></>
                );
              }
              return (
                <BlackTr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </BlackTr>
              );
            })}
          </tbody>
        </table>
      </TableBody>
      <ButtonWrapper>
        <div style={{ width: "160px" }}>
          <div>총 매출</div>
          <div>예상 : {expectedSum} 원 </div>
          <div>입금 금액 : {actualSum} 원</div>
        </div>
        <ButtonGroup>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>

          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            Big
          >
            Prev
          </Button>
          <span>
            <strong
              style={{ display: "block", width: "100px", textAlign: "center" }}
            >
              {pageIndex + 1} / {pageOptions.length}
            </strong>
          </span>

          <Button onClick={() => nextPage()} disabled={!canNextPage} Big>
            Next
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </ButtonGroup>
        <MovePageWrapper>
          <MovePage
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                alert("잘못 입력하셨습니다");
                e.target.value = "";
                return;
              }
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
          <div>페이지로 이동</div>
        </MovePageWrapper>
      </ButtonWrapper>
    </StyleTable>
  );
};
export default CommercialTable;
