import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";

import CheckBox from "../CheckBox";
import { RadioBox, RadioInput, RadioLabel } from "pages/LoginAndSignUp/SignUp";
import axios from "axios";
// style_Components
const StyleTable = styled.table`
  width: 90%;
`;
const TableBody = styled.div`
  margin: 10px 0;
`;
const SearchBar = styled.input`
  padding: 10px;
  margin-top: 10px;
  height: 40px;
  font-size: 14px;
  border: solid 1px gray;
  border-radius: 8px;

  &:focus {
    outline: none !important;
    border: solid 1px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;
const FilterBoxWrapper = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .qq {
    background-color: red;
  }
`;
const ButtonGroup = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Button = styled.button<{ Big?: any }>`
  width: ${(props) => (props.Big ? "70px" : "40px")};
  border-radius: 5px;
  height: 40px;
  font-size: 20px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const MovePageWrapper = styled.div`
  width: 300px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
`;
const MovePage = styled.input`
  width: 50px;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
const UpDownWrapper = styled.span`
  position: fixed;
`;
const DeleteButton = styled.button<{ ready?: any }>`
  width: 70px;
  margin: 15px 80px 0px 0px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.ready ? "#E60A2F" : "gray")};
  color: white;
  cursor: ${(props) => (props.ready ? "pointer" : "block")};
`;
const CreateButton = styled.button`
  width: 80px;
  margin-right: 20px;
  height: 40px;
  border: solid 1px #019868;
  border-radius: 5px;
  background-color: white;
  color: #019868;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
export const OutModalButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  border: 0.5px solid gray;
  background-color: white;
  cursor: pointer;
  font-size: 20px;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
export const ModalView = styled.div.attrs((props) => ({
  role: "dialog",
}))`
  width: 45%;
  height: 70%;
  text-align: start;
  text-decoration: none;
  padding: 30px 90px;
  background-color: white;
  border-radius: 30px;
`;

export const ModalBackdrop = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

const ModifyButton = styled.button`
  width: 100%;
  font-size: 15px;
  height: 40px;
  border: solid 1px #019868;
  border-radius: 5px;
  background-color: white;
  color: #019868;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const ModalContentWrapper = styled.div`
  width: 100%;
  height: 9%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;
export const ModalContentTitle = styled.span`
  width: 200px;
  font-size: 24px;
  font-weight: bold;
`;
export const ModalContentInput = styled.input`
  width: 90%;
  padding: 10px;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 3px;
  border: solid 2px gray;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
  }
`;
const ModalActiveBtn = styled.button`
  width: 47%;
  height: 50px;
  background-color: white;
  font-size: 16px;
  border-radius: 3px;
  border: 1px solid #019868;
  cursor: pointer;
`;
const ModalTextArea = styled.textarea`
  width: 90%;
  height: 100%;
  font-size: 18px;
  padding: 10px;
  border-radius: 5px;
  border: solid 2px gray;
  resize: none;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
  }
`;
const ModalFileSelect = styled.div`
  display: flex;
  width: 90%;
  font-size: 18px;
  label {
    width: 100px;
    height: 50px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    vertical-align: middle;
    background-color: #019868;
    cursor: pointer;
    border: none;
    border-radius: 0.25em;
    margin-left: 10%;
  }
  button {
    width: 80px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 0.75em;
    cursor: pointer;
    border: 1px solid #e60a2f;
    background-color: #e60a2f;
    color: white;
    border-radius: 0.25em;
    margin-left: 5px;
  }
  input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
`;

export const SaveButton = styled.button`
  width: 100%;
  height: 100%;
  border: solid 1px #019868;
  border-radius: 5px;
  background-color: white;
  font-size: 24px;
  color: #019868;
  cursor: pointer;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const COLUMNS = [
  {
    Header: "번호",
    accessor: "id",
  },
  {
    Header: "제목",
    accessor: "title",
  },

  {
    Header: "등록일",
    accessor: "createdAt",
  },
  {
    Header: "active",
    accessor: "active",
  },
];

const AppNoticeTable: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [checkedId, setCheckedId] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [boardId, setBoardId] = useState(1);
  const [btnState, setBtnState] = useState("");

  const [detailId, setDetailId] = useState("");
  const [detailTitle, setDetailTitle] = useState("");
  const [detailDescription, setDetailDescription] = useState("");
  // const [detailCompany_user, setDetailCompany_user] = useState("");
  const [detailActive, setDetailActive] = useState<any>(true);
  const [detailContent, setDetailContent] = useState("");
  const [detailThumbnail, setDetailThumbnail] = useState<any>();
  // const [detailFiles, setDetailFiles] = useState<any>();
  const [detailThumbnailName, setDetailThumbnailName] = useState<any>();
  const [detailFilesName, setDetailFilesName] = useState<any>();
  const myName = localStorage.getItem("NAME");

  const openModalHandler = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    // init detail data
    setDetailId("");
    setDetailTitle("");
    setDetailDescription("");
    // setDetailCompany_user("");
    setDetailActive(false);
    setDetailContent("");
    setDetailThumbnail("");
    // setDetailFiles("");
    setDetailThumbnailName("");
    setDetailFilesName("");
  };
  // 첫 데이터 가져오는 부분
  useEffect(() => {
    const offset = 1000 * 60 * 60 * 9;
    const datas: any = [];
    axios
      .get(
        `https://api2.riderlog-backoffice.net/post?appOrWebId=1&boardId=${boardId}`
      )
      .then((response) => {
        response.data.map((item: any) => {
          return datas.push({
            id: item.id,
            title: item.title,
            content: item.content,
            active: item.active.toString(),
            // company_user: item.company_user.name,
            createdAt: new Date(
              new Date(item.createdAt).getTime() + offset
            ).toLocaleString("ko-KR", { timeZone: "UTC" }),
            // view: item.view,
          });
        });
        setUsers(datas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [boardId]);

  const columns = useMemo(() => COLUMNS, []);

  const data = users;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
          {
            id: "modify",
            Header: "수정하기",
            Cell: ({ row }: any) => (
              <ModifyButton
                onClick={() => {
                  // 기존
                  setBtnState("U");
                  axios
                    .get(
                      `https://api2.riderlog-backoffice.net/post/${row.original.id}`
                    )
                    .then((response) => {
                      setDetailId(response.data.id);
                      setDetailTitle(response.data.title);
                      setDetailDescription(response.data.description);
                      setDetailActive(response.data.active);
                      setDetailContent(response.data.content);
                      // setDetailCompany_user(response.data.company_user.name);

                      if (response.data.files.length === 0) {
                        // 둘다 없음
                        console.log("둘다 없음");
                        setDetailThumbnailName("");
                        setDetailFilesName("");
                      } else if (response.data.files.length === 1) {
                        if (response.data.thumbnail) {
                          // 썸네일만 존재
                          console.log("썸네일 존재");
                          setDetailThumbnailName(response.data.thumbnail.name);
                          setDetailFilesName("");
                        } else {
                          // 파일만 존재
                          console.log("파일 존재");
                          setDetailThumbnailName("");
                          setDetailFilesName(response.data.files[0].name);
                        }
                      } else if (response.data.files.length === 2) {
                        // 둘다 존재
                        console.log("둘다 존재");
                        setDetailThumbnailName(response.data.thumbnail.name);
                        const File = response.data.files.filter((e: any) => {
                          if (e.name !== response.data.thumbnail.name) {
                            return true;
                          }
                        });
                        setDetailFilesName(File[0].name);
                        console.log("thumb :", response.data.thumbnail.name);
                        console.log("files :", File[0].name);
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  openModalHandler();
                }}
              >
                수정하기
              </ModifyButton>
            ),
          },
        ];
      });
    }
  );
  const { pageIndex, globalFilter } = state;
  // 등록 부분
  const createData = () => {
    let form = new FormData();
    form.append("title", detailTitle);
    form.append("content", detailContent);
    form.append("appOrWebId", "1");
    form.append("boardId", boardId.toString());
    form.append("description", detailDescription);
    form.append("active", detailActive);
    if (detailThumbnail) {
      form.append("thumbnail", detailThumbnail[0]);
      console.log(detailThumbnail[0], " : thumbnail File Name");
    } else {
      if (!detailThumbnailName) {
        form.append("delete_thumb", "1");
        console.log("delete delete_thumb");
      }
    }
    // if (detailFiles) {
    //   form.append("attached", detailFiles[0]);
    //   console.log(detailFiles[0], " : file Name");
    // } else {
    //   if (!detailFilesName) {
    //     form.append("delete_attached", "1");
    //     console.log("delete attached");
    //   }
    // }

    axios
      .post(`https://api2.riderlog-backoffice.net/post/register`, form, {
        headers: {
          authorization: localStorage.getItem("JWT"),
        },
      })
      .then((res) => {
        console.log("Success Create", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // 생성 부분
  const updateData = () => {
    let form = new FormData();
    form.append("title", detailTitle);
    form.append("content", detailContent);
    form.append("appOrWebId", "1");
    form.append("boardId", boardId.toString());
    form.append("description", detailDescription);
    form.append("active", detailActive);
    if (detailThumbnail) {
      form.append("thumbnail", detailThumbnail[0]);
      console.log(detailThumbnail[0], " : thumbnail File Name");
    } else {
      if (!detailThumbnailName) {
        form.append("delete_thumb", "1");
        console.log("delete delete_thumb");
      }
    }
    // if (detailFiles) {
    //   form.append("attached", detailFiles[0]);
    //   console.log(detailFiles[0], " : file Name");
    // } else {
    //   if (!detailFilesName) {
    //     form.append("delete_attached", "1");
    //     console.log("delete attached");
    //   }
    // }

    axios
      .patch(`https://api2.riderlog-backoffice.net/post/${detailId}`, form, {
        headers: {
          authorization: localStorage.getItem("JWT"),
        },
      })
      .then((res) => {
        console.log("Success Modify Files", res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 삭제
  useEffect(() => {
    setCheckedId(selectedFlatRows);
  }, [selectedFlatRows]);

  // const changeNotice
  const DeleteChecked = () => {
    let DeleteResultId: number[] = [];
    checkedId.map((row) => {
      DeleteResultId.push(row.original.id);
    });
    // 체크된 row의 id
    console.log(DeleteResultId);

    if (window.confirm(DeleteResultId.length + "개를 삭제 하시겠습니까?")) {
      axios
        .delete("https://api2.riderlog-backoffice.net/post", {
          data: { postId: DeleteResultId },
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        })
        .then(() => {
          alert("삭제 성공하였습니다.");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <>
      {isOpen ? (
        <ModalBackdrop>
          <ModalView>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <OutModalButton
                onClick={() => {
                  closeModal();
                  setBtnState("");
                }}
              >
                x
              </OutModalButton>
            </div>
            <ModalContentWrapper>
              <ModalContentTitle>* 제목 :</ModalContentTitle>
              <ModalContentInput
                type="text"
                defaultValue={detailTitle}
                onChange={(e) => {
                  setDetailTitle(e.target.value);
                }}
              />
            </ModalContentWrapper>
            <ModalContentWrapper>
              <ModalContentTitle>간략 설명 :</ModalContentTitle>
              <ModalContentInput
                type="text"
                defaultValue={detailDescription}
                onChange={(e) => {
                  setDetailDescription(e.target.value);
                }}
              />
            </ModalContentWrapper>
            <ModalContentWrapper>
              <ModalContentTitle> 표시 :</ModalContentTitle>
              {detailActive === true ? (
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <ModalActiveBtn
                    style={{
                      backgroundColor: "#019868",
                      color: "white",
                    }}
                    onClick={() => {
                      setDetailActive(true);
                    }}
                  >
                    활성화
                  </ModalActiveBtn>
                  <ModalActiveBtn
                    onClick={() => {
                      setDetailActive(false);
                    }}
                  >
                    비활성화
                  </ModalActiveBtn>
                </div>
              ) : (
                <div
                  style={{
                    width: "90%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <ModalActiveBtn
                    onClick={() => {
                      setDetailActive(true);
                    }}
                  >
                    활성화
                  </ModalActiveBtn>
                  <ModalActiveBtn
                    style={{
                      backgroundColor: "#019868",
                      color: "white",
                    }}
                    onClick={() => {
                      setDetailActive(false);
                    }}
                  >
                    비활성화
                  </ModalActiveBtn>
                </div>
              )}
            </ModalContentWrapper>
            <ModalContentWrapper>
              <ModalContentTitle> 썸네일 :</ModalContentTitle>
              <ModalFileSelect>
                <input
                  type="file"
                  id="thumbnail"
                  accept="image/jpg, image/png, image/jpeg"
                  onChange={(e) => {
                    setDetailThumbnail(e.target.files);
                    setDetailThumbnailName(e.target.files![0].name);
                  }}
                />
                <div
                  style={{
                    width: "80%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {detailThumbnailName
                    ? detailThumbnailName
                    : "파일을 선택해 주세요"}
                </div>

                <label htmlFor="thumbnail">업로드</label>
                <button
                  onClick={() => {
                    setDetailThumbnail(null);
                    setDetailThumbnailName(null);
                  }}
                >
                  x
                </button>
              </ModalFileSelect>
            </ModalContentWrapper>
            <ModalContentWrapper style={{ height: "40%" }}>
              <ModalContentTitle>
                {boardId === 1 || boardId === 2 ? "* 내용 :" : "* URL :"}
              </ModalContentTitle>
              <ModalTextArea
                rows={5}
                cols={33}
                defaultValue={detailContent}
                onChange={(e) => {
                  setDetailContent(e.target.value);
                }}
              ></ModalTextArea>
            </ModalContentWrapper>

            <div
              style={{
                width: "100%",
                height: "8%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <SaveButton
                onClick={() => {
                  if (detailTitle && detailContent) {
                    if (
                      detailThumbnailName &&
                      detailFilesName &&
                      detailThumbnailName === detailFilesName
                    ) {
                      alert("썸네일과 파일의 이름은 같을 수 없습니다.");
                    } else {
                      if (btnState === "C") {
                        createData();
                        alert("저장에 성공하였습니다.");
                      } else if (btnState === "U") {
                        updateData();
                        alert("저장에 성공하였습니다.");
                      } else {
                        console.log("error");
                      }
                      closeModal();
                      window.location.reload();
                    }
                  } else {
                    alert("제목과 내용은 필수 입니다.");
                  }
                }}
              >
                저장하기
              </SaveButton>
            </div>
          </ModalView>
        </ModalBackdrop>
      ) : null}

      <StyleTable>
        <FilterBoxWrapper>
          <div>
            <RadioBox>
              <div style={{ marginRight: "15px" }}>
                <RadioInput
                  type="radio"
                  onChange={() => {
                    setBoardId(1);
                  }}
                  value={"notice"}
                  name={"title"}
                  defaultChecked
                />
                <RadioLabel>공지사항</RadioLabel>
              </div>

              <div style={{ marginRight: "15px" }}>
                <RadioInput
                  type="radio"
                  onChange={() => {
                    setBoardId(2);
                  }}
                  value={"alarm"}
                  name={"title"}
                />
                <RadioLabel>알람</RadioLabel>
              </div>
              <div style={{ marginRight: "15px" }}>
                <RadioInput
                  type="radio"
                  onChange={() => {
                    setBoardId(3);
                  }}
                  value={"event"}
                  name={"title"}
                />
                <RadioLabel>이벤트</RadioLabel>
              </div>
              <div style={{ marginRight: "15px" }}>
                <RadioInput
                  type="radio"
                  onChange={() => {
                    setBoardId(4);
                  }}
                  value={"contents"}
                  name={"title"}
                />
                <RadioLabel>콘텐츠</RadioLabel>
              </div>
              <div style={{ marginRight: "15px" }}>
                <RadioInput
                  type="radio"
                  onChange={() => {
                    setBoardId(5);
                  }}
                  value={"contents"}
                  name={"title"}
                />
                <RadioLabel>배너</RadioLabel>
              </div>
            </RadioBox>
          </div>
          <SearchBar
            placeholder="Search"
            type="text"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
        </FilterBoxWrapper>
        <TableBody>
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={"ManagerTH"}
                    >
                      {column.render("Header")}
                      <UpDownWrapper>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <GoArrowUp />
                          ) : (
                            <GoArrowDown />
                          )
                        ) : (
                          ""
                        )}
                      </UpDownWrapper>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")} </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </TableBody>
        <ButtonWrapper>
          <div style={{ width: "300px" }}>
            <CreateButton
              onClick={() => {
                openModalHandler();
                setBtnState("C");
              }}
            >
              등록
            </CreateButton>
            ​
            {checkedId.length === 0 ? (
              <DeleteButton>삭제</DeleteButton>
            ) : (
              <DeleteButton ready onClick={DeleteChecked}>
                삭제
              </DeleteButton>
            )}
          </div>
          <ButtonGroup>
            <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </Button>
            ​
            <Button
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
              Big
            >
              Prev
            </Button>
            <span>
              <strong
                style={{
                  display: "block",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                {pageIndex + 1} / {pageOptions.length}
              </strong>
            </span>
            ​
            <Button onClick={() => nextPage()} disabled={!canNextPage} Big>
              Next
            </Button>
            <Button
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </ButtonGroup>
          <MovePageWrapper>
            <MovePage
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                if (Number(e.target.value) < 0) {
                  alert("잘못 입력하셨습니다");
                  e.target.value = "";
                  return;
                }
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
            <div>페이지로 이동</div>
          </MovePageWrapper>
        </ButtonWrapper>
      </StyleTable>
    </>
  );
};
export default AppNoticeTable;
