import React from "react";
import styled from "styled-components";
import CommercialTable from "components/Commercial_Table";

const CommercialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 50px;
`;
const CommercialTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const Commercial: React.FC = () => {
  return (
    <CommercialWrapper>
      <CommercialTableWrapper>
        <CommercialTable />
      </CommercialTableWrapper>
    </CommercialWrapper>
  );
};

export default Commercial;
