import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Sidebar from "./components/Sidebar";
import Login from "pages/LoginAndSignUp/Login";
import User from "./pages/User";
import DashBoard from "pages/DashBoard";
import CommercialManage from "./pages/CommercialManage";
import Commercial from "./pages/Commercial";
import Comment from "pages/Comment";
import Tasks from "./pages/Tasks";
import SignUp from "pages/LoginAndSignUp/SignUp";
import Detail from "components/UserTable/Detail";
import Managements from "./pages/Managerment";
import AppNotice from "./pages/AppNotice";
import WebNotice from "pages/WebNotice";
import Insurance from "pages/Insurance";
import Subscribe from "pages/Subscribe";
import TotalAmount from "pages/TotalAmount";
import Payment from "pages/Payment/Payment";
import PaymentMain from "pages/Payment/Main";
import PaymentResult from "pages/Payment/Result";

// AiIcons.AiOutlineLoading
const App: React.FC = () => {
    return (
        <>
            <Router>
                <Sidebar />
                <Routes>
                    {/* 가용 */}
                    <Route path="/" element={<Login />} />
                    <Route path="/signUpPage" element={<SignUp />} />
                    <Route path="/dashboard" element={<DashBoard />} />
                    <Route path="/user" element={<User />} />
                    <Route path="/detail" element={<Detail />} />
                    <Route path="/managements" element={<Managements />} />
                    <Route path="/commercialManage" element={<CommercialManage />} />
                    <Route path="/commercial" element={<Commercial />} />
                    <Route path="/AppNotice" element={<AppNotice />} />
                    <Route path="/WebNotice" element={<WebNotice />} />
                    <Route path="/Insurance" element={<Insurance />} />
                    <Route path="/Subscribe" element={<Subscribe />} />
                    <Route path="/TotalAmount" element={<TotalAmount />} />
                    <Route path="/Comment" element={<Comment />} />
                    {/* Payment */}
                    {/* https://rider-backoffice.net */}
                    <Route path="/Payment" element={<Payment />} />
                    <Route path="/PaymentMain" element={<PaymentMain />} />
                    <Route path="/PaymentResult" element={<PaymentResult />} />
                    {/* 비가용 */}
                    <Route path="/tasks" element={<Tasks />} />
                </Routes>
            </Router>
        </>
    );
};

export default App;
