import React from "react";
import styled from "styled-components";
const DashBoardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;
const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 100%;
`;
const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45%;
  height: 45%;
  border: solid 1px gray;
  border-radius: 5px;
  box-shadow: 0 6px 6px gray;
  margin: 20px;
`;

const DashBoard: React.FC = () => {
  return (
    <DashBoardWrapper>
      <Wrapper>
        <Card></Card>
        <Card></Card>
        <Card></Card>
        <Card></Card>
      </Wrapper>
    </DashBoardWrapper>
  );
};

export default DashBoard;
