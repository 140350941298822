import React from "react";
import "./index.css";
import { createRoot } from "react-dom/client";
import { GlobalStyle } from "./styles/GlobalStyles";
import App from "./App";

const container = document.getElementById("root") as HTMLElement;
const root = createRoot(container);
root.render(
  // <React.StrictMode>
  <>
    <GlobalStyle />
    <App />
  </>
  // {/* </React.StrictMode> */}
);
