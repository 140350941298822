import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useRowSelect,
} from "react-table";

import CheckBox from "../CheckBox";
import axios from "axios";

// style_Components
const StyleTable = styled.table`
  width: 90%;
`;
const TableBody = styled.div`
  margin: 10px 0;
`;
const SearchBar = styled.input`
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
  border: solid 2px gray;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;

const FilterBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonGroup = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Button = styled.button<{ Big?: any }>`
  width: ${(props) => (props.Big ? "70px" : "40px")};
  border-radius: 5px;
  height: 40px;
  font-size: 20px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const MovePageWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;
const MovePage = styled.input`
  width: 50px;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UpDownWrapper = styled.span`
  position: fixed;
`;
const DeleteButton = styled.button<{ ready?: any }>`
  width: 70px;
  margin: 15px 80px 0px 0px;
  height: 40px;
  border: none;
  border-radius: 5px;
  background-color: ${(props) => (props.ready ? "red" : "gray")};
  color: white;
  cursor: ${(props) => (props.ready ? "pointer" : "block")};
`;

export const COLUMNS = [
  {
    Header: "이름",
    accessor: "name",
  },
  {
    Header: "전화번호",
    accessor: "phoneNumber",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "성별",
    accessor: "sex",
  },
  {
    Header: "부서",
    accessor: "dept",
  },
  {
    Header: "소속",
    accessor: "team",
  },
];

const ManagerTable: React.FC = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [checkedId, setCheckedId] = useState<any[]>([]);

  useEffect(() => {
    const datas: any = [];
    axios
      .get("https://api2.riderlog-backoffice.net/companyUser/", {
        headers: {
          authorization: localStorage.getItem("JWT"),
        },
      })
      .then((response) => {
        console.log("success get users data");
        response.data.map((item: any) => {
          return datas.push({
            id: item.id,
            name: item.name,
            phoneNumber: item.phoneNumber,
            email: item.email,
            sex: item.sex,
            dept: item.dept,
            team: item.team,
          });
        });
        setUsers(datas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = useMemo(() => COLUMNS, []);
  const data = users;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },

    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <CheckBox {...getToggleAllRowsSelectedProps()} />
            ),
            Cell: ({ row }) => (
              <CheckBox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...columns,
        ];
      });
    }
  );

  const { pageIndex, globalFilter } = state;

  useEffect(() => {
    setCheckedId(selectedFlatRows);
  }, [selectedFlatRows]);

  const DeleteChecked = () => {
    let DeleteResultId: number[] = [];
    checkedId.map((row) => {
      DeleteResultId.push(row.original.id);
    });
    // 체크된 row의 id
    console.log(DeleteResultId);
    if (window.confirm(DeleteResultId.length + "개를 삭제 하시겠습니까?")) {
      axios
        .delete("https://api2.riderlog-backoffice.net/companyUser", {
          data: { id: DeleteResultId },
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        })
        .then(() => {
          alert("삭제 성공하였습니다.");
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <StyleTable>
      <FilterBoxWrapper>
        <div />
        <SearchBar
          placeholder="Search"
          type="text"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </FilterBoxWrapper>
      <TableBody>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={"ManagerTH"}
                  >
                    {column.render("Header")}
                    <UpDownWrapper>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <GoArrowUp />
                        ) : (
                          <GoArrowDown />
                        )
                      ) : (
                        ""
                      )}
                    </UpDownWrapper>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")} </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </TableBody>
      <ButtonWrapper>
        {checkedId.length === 0 ? (
          <DeleteButton>삭제</DeleteButton>
        ) : (
          <DeleteButton ready onClick={DeleteChecked}>
            삭제
          </DeleteButton>
        )}

        <ButtonGroup>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>

          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            Big
          >
            Prev
          </Button>
          <span>
            <strong
              style={{ display: "block", width: "100px", textAlign: "center" }}
            >
              {pageIndex + 1} / {pageOptions.length}
            </strong>
          </span>

          <Button onClick={() => nextPage()} disabled={!canNextPage} Big>
            Next
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </ButtonGroup>
        <MovePageWrapper>
          <MovePage
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                alert("잘못 입력하셨습니다");
                e.target.value = "";
                return;
              }
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
          <div>페이지로 이동</div>
        </MovePageWrapper>
      </ButtonWrapper>
    </StyleTable>
  );
};
export default ManagerTable;
