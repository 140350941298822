import ManagerTable from "components/ManagerTable/Manager_Table";
import React from "react";
import styled from "styled-components";

const ManagementWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    font-size: 50px;
`;

const ManagementTableWrapper = styled.div`
    width: 100vh;
    height: 85vh;
    display: flex;
    justify-content: center;
`;

const Managements: React.FC = () => {
    return (
        <ManagementWrapper>
            <ManagementTableWrapper>
                <ManagerTable />
            </ManagementTableWrapper>
        </ManagementWrapper>
    );
};

export default Managements;
