import React from "react";
import styled from "styled-components";
import TotalAmountTable from "components/TotalAmountTable/TotalAmountTable";

const TotalAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 50px;
`;
const TotalAmountTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const TotalAmount: React.FC = () => {
  return (
    <TotalAmountWrapper>
      <TotalAmountTableWrapper>
        <TotalAmountTable />
      </TotalAmountTableWrapper>
    </TotalAmountWrapper>
  );
};

export default TotalAmount;
