import React, { useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

// style_components
const LoginPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
`;
const Wrapper = styled.div`
  width: 40%;
  height: 80%;
  background-color: white;
  border-radius: 10px;
  border: solid 2px #019868;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;
const Title = styled.div`
  width: 100%;
  font-size: 36px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

const InputWrapper = styled.div`
  width: 60%;
  height: 70%;
  display: flex;
  flex-wrap: wrap;
`;

const IdAndPasswordTitle = styled.div`
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 20px;
`;
const InputBox = styled.input`
  width: 100%;
  padding: 10px;
  font-size: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  border: solid 2px gray;
  margin-bottom: 20px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
  }
`;
const SubmitButton = styled.button`
  font-size: 21px;
  font-weight: bold;
  width: 500px;
  height: 60px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;

  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const SignUpLink = styled(Link)`
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 50px;
  font-weight: bold;
  color: #019868;
  border: solid 1px #019868;
  background-color: white;
  border-radius: 5px;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;

const CenterWrapper = styled.div`
  width: "100%";
  display: "flex";
  align-items: "center";
  justify-content: "center";
`;

const Login: React.FC = () => {
  const [id, setId] = useState();
  const [password, setPassword] = useState();

  const onChangeId = (e: any) => {
    setId(e.target.value);
  };
  const onChangePassword = (e: any) => {
    setPassword(e.target.value);
  };

  const navigate = useNavigate();
  const checkLogin = () => {
    //회사로그인
    axios
      .post("https://api2.riderlog-backoffice.net/companyUser/signIn", {
        EMAIL: id,
        PASSWORD: password,
      })
      .then((res) => {
        if (res.data.MESSAGE === "USER_NOT_FOUND") {
          alert("아이디가 일치하지 않습니다");
        } else if (res.data.MESSAGE === "WRONG_PASSWORD") {
          alert("비밀번호가 일치하지 않습니다");
        } else {
          localStorage.setItem("JWT", res.data.JWT);
          localStorage.setItem("NAME", res.data.USERNAME);
          localStorage.setItem("TEAM", res.data.TEAM);
          navigate("/user");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOnKeyPress = (e: any) => {
    if (e.key === "Enter") {
      checkLogin(); // Enter 입력이 되면 클릭 이벤트 실행
    }
  };
  return (
    <LoginPage>
      <Wrapper>
        <Title>Back Office</Title>
        <InputWrapper>
          <div style={{ width: "100%" }}>
            <IdAndPasswordTitle>E-mail</IdAndPasswordTitle>
            <InputBox
              type="text"
              onChange={onChangeId}
              value={id || ""}
              name={id}
              onKeyDown={handleOnKeyPress}
            />
            <IdAndPasswordTitle>Password</IdAndPasswordTitle>
            <InputBox
              type="password"
              onChange={onChangePassword}
              value={password || ""}
              name={password}
              onKeyDown={handleOnKeyPress}
            />
          </div>
          <CenterWrapper
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SubmitButton
              onClick={() => {
                checkLogin();
              }}
            >
              Login
            </SubmitButton>
          </CenterWrapper>
          <CenterWrapper
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SignUpLink to={"/signUpPage"}>회원가입</SignUpLink>
          </CenterWrapper>
        </InputWrapper>
      </Wrapper>
    </LoginPage>
  );
};

export default Login;
