import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";

import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as HiIcons from "react-icons/hi";
import * as RiIcons from "react-icons/ri";

import { SidebarData } from "./SidebarData";

const Navbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
  background-color: #019868;
`;

const Maintitle = styled.span`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 24px;
  margin-right: 20px;
  color: #ffffff;
`;

const MenuIconOpen = styled.button`
  display: flex;
  font-size: 1.5rem;
  margin-left: 15px;
  color: #ffffff;
  background-color: #019868;
  border: none;
  cursor: pointer;
`;

const MenuIconClose = styled.button`
  display: flex;
  justify-content: end;
  font-size: 1.5rem;
  margin-top: 0.75rem;
  margin-left: 0.75rem;
  color: #ffffff;
  height: 2.7rem;
  border: none;
  background-color: #242424;
  cursor: pointer;
`;
const BackButton = styled(Link)`
  display: flex;
  font-size: 1.5rem;
  margin-right: 15px;
  color: #ffffff;
`;
const SidebarMenu = styled.div<{ close: boolean }>`
  width: 250px;
  height: 100vh;
  background-color: #242424;
  position: fixed;
  top: 0;
  left: ${({ close }) => (close ? "0" : "-100%")};
  transition-duration: 0.3s;
`;

const MenuItems = styled.li<{ SlideMenu?: any }>`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  width: ${(props) => (props.SlideMenu ? "80%" : "100%")};
  margin-left: ${(props) => (props.SlideMenu ? "18px" : "0px")};
  height: 90px;
  padding: 1rem 0 1.25rem;
`;

const MenuItemLinks = styled(Link)<{ SlideMenu?: any }>`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: ${(props) => (props.SlideMenu ? "16px" : "20px")};
  text-decoration: none;
  color: #ffffff;
  &:hover {
    background-color: #ffffff;
    color: #242424;
    width: 100%;
    height: 65px;
    text-align: center;
    border-radius: 5px;
    margin: 0 0.2rem;
  }
`;
const Divider = styled.div`
  width: 100%;
  height: 1.5px;
  background-color: #b7b7b7;
`;
const DropDiv = styled.div<{ Show: boolean }>`
  visibility: ${(props) => (props.Show ? "visible" : "hidden")};
  transition: 0.3s ease-out;
  opacity: ${(props) => (props.Show ? "1" : "0")};
  max-height: ${(props) => (props.Show ? "auto" : "0")};
  background-color: #303030;
`;
const DropButton = styled.div`
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  height: 90px;
  padding: 1rem 0 1.25rem;
`;

const DropContent = styled.div`
  display: flex;
  align-items: center;
  padding: 0 2rem;
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;
  cursor: pointer;
  &:hover {
    background-color: #ffffff;
    color: #242424;
    width: 100%;
    height: 65px;
    text-align: center;
    border-radius: 5px;
    margin: 0 0.2rem;
  }
`;
const Sidebar: React.FC = () => {
  const [close, setClose] = useState(false);
  const [clickSales, setClickSales] = useState(false);
  const [clickCommunity, setClickCommunity] = useState(false);
  const showSidebar = () => setClose(!close);

  // 현재 url 알기위함
  const location = useLocation();
  // 이동을 위함.
  const navigate = useNavigate();
  const jwt = localStorage.getItem("JWT");
  useEffect(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/signUpPage" ||
      location.pathname === "/Payment" ||
      location.pathname === "/PaymentMain" ||
      location.pathname === "/PaymentResult"
    ) {
      console.log("pass");
    } else if (!jwt) {
      alert("로그인 후 이용해주세요");
      navigate("/");
    }
  }, [location.pathname]);

  return (
    <>
      <Navbar>
        {location.pathname === "/" ||
        location.pathname === "/signUpPage" ||
        location.pathname === "/Payment" ||
        location.pathname === "/PaymentMain" ||
        location.pathname === "/PaymentResult" ? (
          <div style={{ marginLeft: "15px" }}></div>
        ) : (
          <MenuIconOpen onClick={showSidebar}>
            <FaIcons.FaBars />
          </MenuIconOpen>
        )}

        <Maintitle>라이더 로그</Maintitle>
        {location.pathname.includes("/detail") ? (
          <BackButton to="/user">
            <HiIcons.HiBackspace />
          </BackButton>
        ) : (
          <div></div>
        )}
      </Navbar>
      <SidebarMenu close={close}>
        <MenuIconClose onClick={showSidebar}>
          <FaIcons.FaTimes />
        </MenuIconClose>
        <Divider />

        {SidebarData.map((item, index) => {
          return (
            <MenuItems key={index}>
              <MenuItemLinks
                to={item.path}
                onClick={() => {
                  setClose(false);
                }}
              >
                {item.icon}
                <span style={{ marginLeft: "16px" }}>{item.title}</span>
              </MenuItemLinks>
            </MenuItems>
          );
        })}
        {/* <DropButton>
          <DropContent
            onClick={() => {
              if (!clickSales) {
                setClickSales(true);
                if (clickCommunity) {
                  setClickCommunity(false);
                }
              } else {
                setClickSales(false);
              }
            }}
          >
            {<FaIcons.FaRegMoneyBillAlt />}
            <span style={{ marginLeft: "16px" }}> {"매출 관리"}</span>
          </DropContent>
        </DropButton> */}

        <DropDiv Show={clickSales}>
          {/* 총매출 잠시 보류  */}
          {/* <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/TotalAmount"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<FaIcons.FaCalculator />}
              <span style={{ marginLeft: "16px" }}>{"총매출"}</span>
            </MenuItemLinks>
          </MenuItems> */}
          <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/commercial"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<FaIcons.FaAdversal />}
              <span style={{ marginLeft: "16px" }}>{"광고"}</span>
            </MenuItemLinks>
          </MenuItems>
          <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/Insurance"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<RiIcons.RiHospitalLine />}
              <span style={{ marginLeft: "16px" }}>{"보험"}</span>
            </MenuItemLinks>
          </MenuItems>
          <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/Subscribe"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<HiIcons.HiOutlinePencilAlt />}
              <span style={{ marginLeft: "16px" }}>{"구독"}</span>
            </MenuItemLinks>
          </MenuItems>
        </DropDiv>
        <DropButton>
          <DropContent
            onClick={() => {
              if (!clickCommunity) {
                setClickCommunity(true);
                if (clickSales) {
                  setClickSales(false);
                }
              } else {
                setClickCommunity(false);
              }
            }}
          >
            {<HiIcons.HiChatAlt />}
            <span style={{ marginLeft: "16px" }}> {"커뮤니티 관리"}</span>
          </DropContent>
        </DropButton>
        <DropDiv Show={clickCommunity}>
          <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/AppNotice"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<RiIcons.RiSmartphoneLine />}
              <span style={{ marginLeft: "16px" }}>{"앱"}</span>
            </MenuItemLinks>
          </MenuItems>
          {/* <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/WebNotice"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<RiIcons.RiComputerLine />}
              <span style={{ marginLeft: "16px" }}>{"웹"}</span>
            </MenuItemLinks>
          </MenuItems>
          <MenuItems SlideMenu>
            <MenuItemLinks
              SlideMenu
              to={"/Comment"}
              onClick={() => {
                setClose(false);
              }}
            >
              {<RiIcons.RiSmartphoneLine />}
              <span style={{ marginLeft: "16px" }}>{"Comment"}</span>
            </MenuItemLinks>
          </MenuItems> */}
        </DropDiv>

        <div
          style={{
            position: "fixed",
            bottom: "0px",
            width: "250px",
            height: "150px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: "30px", color: "white", marginLeft: "7px" }}>
            {localStorage.getItem("NAME")}
          </div>
          <div>
            <BiIcons.BiLogOut
              style={{
                fontSize: "35px",
                color: "white",
                cursor: "pointer",
                margin: "7px 10px 0 0",
              }}
              onClick={() => {
                localStorage.clear();
                setClose(false);
                navigate("/");
              }}
            />
          </div>
        </div>
      </SidebarMenu>
    </>
  );
};

export default Sidebar;
