import React from "react";
import styled from "styled-components";
import SubscribeTable from "components/SubscribeTable/SubscribeTable";

const SubscribeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 50px;
`;
const SubscribeTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const Subscribe: React.FC = () => {
  return (
    <SubscribeWrapper>
      <SubscribeTableWrapper>
        <SubscribeTable />
      </SubscribeTableWrapper>
    </SubscribeWrapper>
  );
};

export default Subscribe;
