import React from "react";
import styled from "styled-components";

const NoticeWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const NoticeTableWrapper = styled.div`
    width: 100vh;
    height: 85vh;
    display: flex;
    justify-content: center;
`;

const Payment: React.FC = () => {
    return (
        <NoticeWrapper>
            <NoticeTableWrapper>
                <button style={{ width: "100px", height: "100px" }}>s</button>
            </NoticeTableWrapper>
        </NoticeWrapper>
    );
};

export default Payment;
