import WebNoticeTable from "components/NoticeTable/Web_Notice_Table";
import React from "react";
import styled from "styled-components";

const NoticeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const NoticeTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const WebNotice: React.FC = () => {
  return (
    <NoticeWrapper>
      <NoticeTableWrapper>
        <WebNoticeTable />
      </NoticeTableWrapper>
    </NoticeWrapper>
  );
};

export default WebNotice;
