import React, { useMemo, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  // useFilters,
} from "react-table";
import axios from "axios";

// style_Components
const StyleTable = styled.table`
  width: 90%;
`;
const TableBody = styled.div`
  margin: 10px 0;
`;
const SearchBar = styled.input`
  padding: 10px;
  margin-top: 10px;
  font-size: 18px;
  border: solid 2px gray;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;

const FilterBoxWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ButtonGroup = styled.div`
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`;
const Button = styled.button<{ Big?: any }>`
  width: ${(props) => (props.Big ? "70px" : "40px")};
  border-radius: 5px;
  height: 40px;
  font-size: 20px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  &:disabled {
    color: gray;
    border: solid 1px gray;
    cursor: default;
    &:hover {
      color: gray;
      background-color: white;
    }
  }
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const MovePageWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
`;
const MovePage = styled.input`
  width: 50px;
  height: 30px;
  font-size: 16px;
  margin-right: 5px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const UpDownWrapper = styled.span`
  position: fixed;
`;

const BlackTr = styled.tr`
  cursor: pointer;
  color: black;
`;

export const COLUMNS = [
  {
    Header: "Id",
    accessor: "id",
  },
  {
    Header: "이름",
    accessor: "name",
  },
  {
    Header: "전화번호",
    accessor: "phoneNumber",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "소속",
    accessor: "agency",
  },
  {
    Header: "구독일자",
    accessor: "SubscribeStartDate",
  },
  {
    Header: "구독금액",
    accessor: "SubscribeAmount",
  },
  {
    Header: "구독기간",
    accessor: "SubscribeDuringDate",
  },
  {
    Header: "보험가입일자 ",
    accessor: "InsuranceStartDate",
  },
  {
    Header: "보험금액 ",
    accessor: "InsuranceAmount",
  },
];

const UserTable: React.FC = () => {
  const [users, setUsers]: any = useState([]);
  const navigate = useNavigate();

  const moveDetail = (id: string) => {
    navigate(`/detail?id=${id}`, {
      state: {
        id: id,
      },
    });
  };

  useEffect(() => {
    const datas: any = [];
    axios
      .get("https://api2.riderlog-backoffice.net/rider/allUsers")
      .then((response) => {
        console.log("success get users data");
        console.log(response.data.RESULT);

        response.data.RESULT.map((item: any) => {
          if (item.revenues.length === 0) {
            return datas.push({
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber,
              email: item.email,
              agency: item.agency,
              SubscribeStartDate: "",
              SubscribeAmount: "",
              SubscribeDuringDate: "",
              InsuranceStartDate: "",
              InsuranceAmount: "",
            });
          } else if (
            item.revenues.length === 1 &&
            item.revenues[0].revCategory === "구독"
          ) {
            console.log("구독만");
            return datas.push({
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber,
              email: item.email,
              agency: item.agency,
              SubscribeStartDate: item.revenues[0].start_date,
              SubscribeAmount: item.revenues[0].total_amount,
              SubscribeDuringDate: item.revenues[0].months,
              InsuranceStartDate: "",
              InsuranceAmount: "",
            });
          } else if (
            item.revenues.length === 1 &&
            item.revenues[0].revCategory === "보험"
          ) {
            console.log("보험");
            return datas.push({
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber,
              email: item.email,
              agency: item.agency,
              SubscribeStartDate: "",
              SubscribeAmount: "",
              SubscribeDuringDate: "",
              InsuranceStartDate: item.revenues[0].start_date,
              InsuranceAmount: item.revenues[0].total_amount,
            });
          }
          if (
            item.revenues.length === 2 &&
            item.revenues[0].revCategory === "보험"
          ) {
            // 첫번째 애가 보험
            return datas.push({
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber,
              email: item.email,
              agency: item.agency,
              SubscribeStartDate: item.revenues[1].start_date,
              SubscribeAmount: item.revenues[1].total_amount,
              SubscribeDuringDate: item.revenues[1].months,
              InsuranceStartDate: item.revenues[0].start_date,
              InsuranceAmount: item.revenues[0].total_amount,
            });
          } else if (
            item.revenues.length === 2 &&
            item.revenues[0].revCategory === "구독"
          ) {
            // 첫번째 애가 구독
            return datas.push({
              id: item.id,
              name: item.name,
              phoneNumber: item.phoneNumber,
              email: item.email,
              agency: item.agency,
              SubscribeStartDate: item.revenues[0].start_date,
              SubscribeAmount: item.revenues[0].total_amount,
              SubscribeDuringDate: item.revenues[0].months,
              InsuranceStartDate: item.revenues[1].start_date,
              InsuranceAmount: item.revenues[1].total_amount,
            });
          }
        });
        setUsers(datas);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const columns = useMemo(() => COLUMNS, []);
  const data = users;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    prepareRow,
    setGlobalFilter,
  } = useTable(
    {
      // @ts-ignore
      columns,
      data,
    },
    // useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <StyleTable>
      <FilterBoxWrapper>
        <div />
        <SearchBar
          placeholder="Search"
          type="text"
          value={globalFilter || ""}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
      </FilterBoxWrapper>

      <TableBody>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <UpDownWrapper>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <GoArrowUp />
                        ) : (
                          <GoArrowDown />
                        )
                      ) : (
                        ""
                      )}
                    </UpDownWrapper>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);

              return (
                <BlackTr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        onClick={() => {
                          moveDetail(cell.row.values.id);
                        }}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </BlackTr>
              );
            })}
          </tbody>
        </table>
      </TableBody>
      <ButtonWrapper>
        <div style={{ width: "160px" }} />
        <ButtonGroup>
          <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
            {"<<"}
          </Button>

          <Button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            Big
          >
            Prev
          </Button>
          <span>
            <strong
              style={{ display: "block", width: "100px", textAlign: "center" }}
            >
              {pageIndex + 1} / {pageOptions.length}
            </strong>
          </span>

          <Button onClick={() => nextPage()} disabled={!canNextPage} Big>
            Next
          </Button>
          <Button
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </Button>
        </ButtonGroup>
        <MovePageWrapper>
          <MovePage
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              if (Number(e.target.value) < 0) {
                alert("잘못 입력하셨습니다");
                e.target.value = "";
                return;
              }
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
          />
          <div>페이지로 이동</div>
        </MovePageWrapper>
      </ButtonWrapper>
    </StyleTable>
  );
};
export default UserTable;
