import React from "react";
import styled from "styled-components";
import InsuranceTable from "components/InsuranceTable/InsuranceTable";

const InsuranceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
`;
const InsuranceTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const Insurance: React.FC = () => {
  return (
    <InsuranceWrapper>
      <InsuranceTableWrapper>
        <InsuranceTable />
      </InsuranceTableWrapper>
    </InsuranceWrapper>
  );
};

export default Insurance;
