import CommentTable from "components/QuestionTable/Question_Table";
import React from "react";
import styled from "styled-components";

const CommentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CommentTableWrapper = styled.div`
  width: 100vh;
  height: 85vh;
  display: flex;
  justify-content: center;
`;

const Comment: React.FC = () => {
  return (
    <CommentWrapper>
      <CommentTableWrapper>
        <CommentTable />
      </CommentTableWrapper>
    </CommentWrapper>
  );
};

export default Comment;
