import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

const CommercialWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  font-size: 30px;
`;
const CommercialTableWrapper = styled.div`
  width: 80%;
  height: 85vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
// info form
const BannerWrapper = styled.div`
  width: 55%;
  height: 80%;
  border: solid 1px gray;
  border-radius: 5px;
  box-shadow: 0 6px 6px gray;
  padding: 15px;
  display: flex;
  align-items: space-around;
  flex-wrap: wrap;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 1px solid gray;
`;
const RowTitle = styled.div`
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 300px;
`;
const RowData = styled.div`
  width: 300px;
  font-size: 18px;
  height: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// input form
const InputWrapper = styled.div`
  width: 40%;
  height: 80%;
  border: solid 0.6px gray;
  border-radius: 5px;
  box-shadow: 0 6px 6px gray;
  padding: 10px 20px;
`;
const InputGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;
const InputTitle = styled.div`
  width: 50%;
  height: 8rem;
  font-weight: bold;
`;
const InputContent = styled.input`
  width: 60%;
  height: 4rem;
  padding: 10px;
  font-size: 18px;
  border: solid 2px gray;
  border-radius: 8px;
  &:focus {
    outline: none !important;
    border: solid 2px #019868;
    border-radius: 3px;
    border-radius: 8px;
  }
`;
const BannerSelect = styled.select`
  width: 60%;
  height: 4rem;
  padding: 0.7em 0.5em;
  border: 0.6px solid #999;
  font-size: 20px;
  border-radius: 10px;
  &:focus {
    border: 1px solid #019868;
    box-sizing: border-box;
  }
`;
const ButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;
const SubmitButton = styled.button`
  font-size: 21px;
  font-weight: bold;
  width: 80%;
  height: 60px;
  color: #019868;
  border: solid 1px #019868;
  margin: 0 5px;
  background-color: white;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 30px;
  &:hover {
    color: white;
    background-color: #019868;
  }
`;
const DeleteButton = styled.button`
  font-size: 21px;
  width: 80%;
  height: 60px;
  margin-top: 30px;
  border-radius: 5px;
  cursor: pointer;
  color: #777;
  background: #e6e6e6;
  border: 0.3px solid #999;
  &:hover {
    color: black;
    background: #e9e9e9;
  }
`;

const VLine = styled.div`
  border-left: 0.6px solid #808080;
  height: 100%;
`;

const CommercialManage: React.FC = () => {
  const [oneCommercialInfo, setOneCommercialInfo] = useState({
    banner: "",
    expected_amount: "",
    backoffice_client: "",
    duration_month: "",
    start_date: "",
  });
  const [twoCommercialInfo, setTwoCommercialInfo] = useState({
    banner: "",
    expected_amount: "",
    backoffice_client: "",
    duration_month: "",
    start_date: "",
  });
  const [threeCommercialInfo, setThreeCommercialInfo] = useState({
    banner: "",
    expected_amount: "",
    backoffice_client: "",
    duration_month: "",
    start_date: "",
  });
  const [fourCommercialInfo, setFourCommercialInfo] = useState({
    banner: "",
    expected_amount: "",
    backoffice_client: "",
    duration_month: "",
    start_date: "",
  });
  const [buttonState, setButtonState] = useState("");

  const [selectBanner, setSelectBanner] = useState<number>();
  const [selectClient, setSelectClent] = useState<string>();
  // const [selectActual, setSelectActual] = useState<number>();
  const [selectExpected, setSelectExpected] = useState<number>();
  const [selectStartDate, setSelectStartDate] = useState<string>();
  const [selectDuration, setSelectDuration] = useState<number>();
  const [selectExpectedDate, setSelectExpectedDate] = useState<string>();
  // 첫 display가 true인 데이터 호출

  useEffect(() => {
    axios
      .get("https://api2.riderlog-backoffice.net/commercial/", {
        headers: {
          authorization: localStorage.getItem("JWT"),
        },
      })
      .then((res) => {
        res.data.map((id: any) => {
          if (id.banner === 1) {
            setOneCommercialInfo({
              banner: id.banner,
              expected_amount: id.expected_amount,
              backoffice_client: id.backoffice_client.name,
              duration_month: id.duration_month,
              start_date: id.start_date,
            });
          }
          if (id.banner === 2) {
            setTwoCommercialInfo({
              banner: id.banner,
              expected_amount: id.expected_amount,
              backoffice_client: id.backoffice_client.name,
              duration_month: id.duration_month,
              start_date: id.start_date,
            });
          }
          if (id.banner === 3) {
            setThreeCommercialInfo({
              banner: id.banner,
              expected_amount: id.expected_amount,
              backoffice_client: id.backoffice_client.name,
              duration_month: id.duration_month,
              start_date: id.start_date,
            });
          }
          if (id.banner === 4) {
            setFourCommercialInfo({
              banner: id.banner,
              expected_amount: id.expected_amount,
              backoffice_client: id.backoffice_client.name,
              duration_month: id.duration_month,
              start_date: id.start_date,
            });
          }
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onChangerBanner = (e: any) => {
    setSelectBanner(e.target.value);

    const Target = e.target.value;

    switch (Target) {
      case "1":
        if (oneCommercialInfo.banner) {
          setButtonState("delete");
        } else {
          setButtonState("create");
        }
        break;
      case "2":
        if (twoCommercialInfo.banner) {
          setButtonState("delete");
        } else {
          setButtonState("create");
        }
        break;
      case "3":
        if (threeCommercialInfo.banner) {
          setButtonState("delete");
        } else {
          setButtonState("create");
        }
        break;
      case "4":
        if (fourCommercialInfo.banner) {
          setButtonState("delete");
        } else {
          setButtonState("create");
        }
        break;
    }
  };

  const CreateClick = () => {
    console.log(selectExpected);
    console.log(selectExpectedDate);
    console.log(selectStartDate);
    console.log(selectDuration);
    if (
      !selectBanner ||
      !selectExpected ||
      !selectExpectedDate ||
      !selectStartDate ||
      !selectDuration
    ) {
      alert("빈칸이 존재합니다.");
    } else {
      axios
        .post(
          "http://api2.riderlog-backoffice.net/commercial/create",
          {
            banner: selectBanner,
            backoffice_client_name: selectClient,
            expected_amount: selectExpected,
            expected_settlement_date: selectExpectedDate,
            start_date: selectStartDate,
            duration_month: selectDuration,
          },
          {
            headers: {
              authorization: localStorage.getItem("JWT"),
            },
          }
        )
        .then((res) => {
          if (res.data.MESSAGE === "SUCCESS") {
            alert("생성에 성공했습니다");
            window.location.reload();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const DisplayFalseClick = (e: number) => {
    axios
      .patch(
        "https://api2.riderlog-backoffice.net/commercial/update",
        { banner: e },
        {
          headers: {
            authorization: localStorage.getItem("JWT"),
          },
        }
      )
      .then((res) => {
        if (res.data.MESSAGE === "SUCCESS") {
          alert("삭제 성공");
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // input 정규식
  const setSelectExpectedFunction = (e: any) => {
    setSelectExpected(e.target.value.replace(/[^0-9]/g, ""));
  };
  const setSelectDurationFunction = (e: any) => {
    setSelectDuration(e.target.value.replace(/[^0-9]/g, ""));
  };
  const setSelectExpectedDateFunction = (e: any) => {
    setSelectExpectedDate(
      e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`)
    );
  };
  const setSelectStartDateFunction = (e: any) => {
    setSelectStartDate(
      e.target.value
        .replace(/[^0-9]/g, "")
        .replace(/^(\d{4})(\d{2})(\d{2})$/, `$1-$2-$3`)
    );
  };

  return (
    <CommercialWrapper>
      <CommercialTableWrapper>
        <BannerWrapper>
          <Row>
            <RowTitle />
            <VLine />
            <RowTitle>광고주</RowTitle>
            <VLine />
            <RowTitle>계약 금액</RowTitle>
            <VLine />
            <RowTitle>광고 시작일</RowTitle>
            <VLine />
            <RowTitle>기간</RowTitle>
          </Row>
          <Row>
            <RowTitle>배너 1</RowTitle>
            <VLine />
            <RowData>{oneCommercialInfo.backoffice_client}</RowData>
            <VLine />
            <RowData>{oneCommercialInfo.expected_amount}</RowData>
            <VLine />
            <RowData>{oneCommercialInfo.start_date}</RowData>
            <VLine />
            <RowData>{oneCommercialInfo.duration_month}</RowData>
          </Row>
          <Row>
            <RowTitle>배너 2</RowTitle>
            <VLine />
            <RowData>{twoCommercialInfo.backoffice_client}</RowData>
            <VLine />
            <RowData>{twoCommercialInfo.expected_amount}</RowData>
            <VLine />
            <RowData>{twoCommercialInfo.start_date}</RowData>
            <VLine />
            <RowData>{twoCommercialInfo.duration_month}</RowData>
          </Row>
          <Row>
            <RowTitle>배너 3</RowTitle>
            <VLine />
            <RowData>{threeCommercialInfo.backoffice_client}</RowData>
            <VLine />
            <RowData>{threeCommercialInfo.expected_amount}</RowData>
            <VLine />
            <RowData>{threeCommercialInfo.start_date}</RowData>
            <VLine />
            <RowData>{threeCommercialInfo.duration_month}</RowData>
          </Row>
          <Row>
            <RowTitle>배너 4</RowTitle>
            <VLine />
            <RowData>{fourCommercialInfo.backoffice_client}</RowData>
            <VLine />
            <RowData>{fourCommercialInfo.expected_amount}</RowData>
            <VLine />
            <RowData>{fourCommercialInfo.start_date}</RowData>
            <VLine />
            <RowData>{fourCommercialInfo.duration_month}</RowData>
          </Row>
        </BannerWrapper>
        <InputWrapper>
          <InputGroup>
            <InputTitle>배너</InputTitle>
            <BannerSelect onChange={onChangerBanner}>
              <option disabled selected hidden>
                배너 선택
              </option>
              <option key={"banner1"} value={"1"}>
                배너 1
              </option>
              <option key={"banner2"} value={"2"}>
                배너 2
              </option>
              <option key={"banner3"} value={"3"}>
                배너 3
              </option>
              <option key={"banner4"} value={"4"}>
                배너 4
              </option>
            </BannerSelect>
          </InputGroup>

          {/* const [selectActual, setSelectActual] = useState<number>();
  const [selectExpected, setSelectExpected] = useState<number>(); */}

          <InputGroup>
            <InputTitle>광고주</InputTitle>
            <InputContent
              placeholder="별따러가자"
              maxLength={10}
              onChange={(e) => {
                setSelectClent(e.target.value);
              }}
            />
          </InputGroup>

          <InputGroup>
            <InputTitle>계약금액</InputTitle>
            <InputContent
              placeholder="500000"
              maxLength={10}
              value={selectExpected || ""}
              onChange={setSelectExpectedFunction}
            />
          </InputGroup>

          <InputGroup>
            <InputTitle>입금예정일</InputTitle>
            <InputContent
              placeholder="2023-01-01"
              maxLength={10}
              value={selectExpectedDate || ""}
              onChange={setSelectExpectedDateFunction}
            />
          </InputGroup>

          <InputGroup>
            <InputTitle>광고시작</InputTitle>
            <InputContent
              placeholder="2023-03-01"
              maxLength={10}
              value={selectStartDate || ""}
              onChange={setSelectStartDateFunction}
            />
          </InputGroup>

          <InputGroup>
            <InputTitle>기간</InputTitle>
            <InputContent
              placeholder="12"
              maxLength={5}
              value={selectDuration || ""}
              onChange={setSelectDurationFunction}
            />
          </InputGroup>

          <ButtonGroup>
            {buttonState === "create" ? (
              <SubmitButton
                onClick={() => {
                  CreateClick();
                }}
              >
                입력
              </SubmitButton>
            ) : (
              <DeleteButton
                onClick={() => {
                  if (!selectBanner) {
                    alert("배너를 선택해주세요");
                  } else {
                    if (
                      window.confirm(
                        "배너 " + selectBanner + "을 정말 삭제 하시겠습니까?"
                      )
                    ) {
                      DisplayFalseClick(selectBanner!);
                      alert("배너가 삭제되었습니다.");
                      window.location.reload();
                    }
                  }
                }}
              >
                삭제
              </DeleteButton>
            )}
          </ButtonGroup>
        </InputWrapper>
      </CommercialTableWrapper>
    </CommercialWrapper>
  );
};

export default CommercialManage;
